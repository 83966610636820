import { Component, OnInit, OnDestroy } from '@angular/core';

import * as Env from 'src/ver';
import { EnvService } from 'src/app/services/api/env.service';
import { Subscription } from 'rxjs';

export interface INameValue {
  name: string;
  value: string;
}

@Component({
  selector: 'app-env',
  templateUrl: './env.component.html',
  styleUrls: ['./env.component.scss'],
  })
export class EnvComponent implements OnInit, OnDestroy {
  build = Env.BUILD_NUMBER;
  commit = Env.BUILD_COMMIT;
  source = Env.BUILD_SOURCE;
  time: string;
  commitUrl = `https://github.com/mrdelivery/food-rssp-web/commit/${this.commit}`;

  // data:INameValue[] = [
  //   {name:"Build #", value:Env.BUILD_NUMBER},
  //   {name:"Source", value:Env.BUILD_SOURCE},
  //   {name:"Commit", value:Env.BUILD_COMMIT},
  //   {name:"Time", value:Env.BUILD_TIME},
  // ];

  vars: INameValue[] = [];
  changableVars = ['api'];
  refreshRequired = false;
  environmentResetable = false;

  private subEnv: Subscription;

  constructor(
    private env: EnvService,
  ) {
    const t = +Env.BUILD_TIME;
    if (!Number.isNaN(t)) { this.time = new Date(t).toLocaleString(); }
  }

  ngOnInit(): void {
    this.subEnv = this.env.value$.subscribe((values) => {
      this.vars = Object.entries(values).map((value) => {
        const val = { name: value[0], value: value[1], editable: false };
        val.editable = !!~this.changableVars.indexOf(val.name); // eslint-disable-line no-bitwise
        return val;
      })
      // make sure no complex env vars are edited using a text input
        .filter((val) => typeof (val.value) === 'string');
    });
    this.environmentResetable = this.env.hasOverrideValues;

    // also while we are looking at versions request the API version
    this.env.getAPIVersion().subscribe((res) => {
      this.vars.push({ name: 'API version', value: `${res.version} (${res.info})` });
    }, (er) => {
      this.vars.push({ name: 'API version', value: '(failed to load)' });
    });
  }

  edit(v: INameValue) {
    // eslint-disable-next-line no-alert
    const newV = prompt(`Override value for "${v.name}"`, v.value);
    if (newV === null) { return; } // prompt cancelled
    if (newV === v.value) { return; } // value unchanged
    this.env.override({ [v.name]: newV });
    this.refreshRequired = true;
  }

  revert() {
    this.env.reset();
    window.location.reload();
  }

  refresh() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.subEnv.unsubscribe();
  }
}
