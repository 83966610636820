import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import {
  map, pairwise, startWith, shareReplay,
} from 'rxjs/operators';

export interface ScrollUpdate {
  viewHeight: number;
  scrollOffset: number;
  documentHeight: number;
  distance: number;
  contentFits: boolean;
  scrollPercentage: number;
}

@Injectable({
  providedIn: 'root',
  })
export class ScrollAssistService {
  /**
   * Scroll update stream. !REMEMBER TO UNSUBSCRIBE COMPONENTS ON DESTRUCTION!
   */
  update$: Observable<ScrollUpdate>;

  get viewHeight(): number {
    return window.innerHeight;
  }
  get scrollOffset(): number {
    return window.scrollY;
  }
  get documentHeight(): number {
    return document.body.offsetHeight;
  }
  get contentFits(): boolean {
    return this.viewHeight > this.documentHeight;
  }
  get scrollPercentage(): number {
    if (this.viewHeight > this.documentHeight) { return 0; }
    return this.scrollOffset / (this.documentHeight - this.viewHeight);
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
  ) {
    if (isPlatformServer(this.platform)) {
      this.update$ = of();
      return;
    }

    this.update$ = fromEvent<Event>(window, 'scroll').pipe(
      map(() => ({
        viewHeight: window.innerHeight,
        scrollOffset: window.scrollY,
        documentHeight: document.body.offsetHeight,
      })),
      pairwise(),
      startWith([
        { viewHeight: 1, scrollOffset: 0, documentHeight: 0 },
        { viewHeight: 1, scrollOffset: 0, documentHeight: 0 },
      ]),
      map(([prev, cur]) => ({
        ...cur,
        distance: cur.scrollOffset - prev.scrollOffset,
        contentFits: cur.viewHeight > cur.documentHeight,
        scrollPercentage: (cur.viewHeight < cur.documentHeight)
          ? cur.scrollOffset / (cur.documentHeight - cur.viewHeight) : 0,
      })),
      shareReplay(),
    );
  }
}
