import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, AuthState } from 'src/app/services/auth.service';
import { PopupService } from 'src/app/services/popup.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileState, UserService } from 'src/app/services/api/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  })
export class HomeComponent implements OnInit, OnDestroy {
  showLogin = false;
  showDash = false;
  hasProfileIssue = false;

  salesData = {
    data: [
      {
        legend: 'This period',
        sales: [200, 400, 300, 800, 500, 1100, 700],
      },
      {
        legend: 'Last period',
        sales: [240, 300, 250, 400, 450, 900, 800],
      },
    ],
    labels: [
      'T 22 Jun',
      'F 23 Jun',
      'S 24 Jun',
      'S 25 Jun',
      'M 26 Jun',
      'T 27 Jun',
      'W 28 Jun',
    ],
  };

  // selectedType:string = this.types[0].id;

  // selectedPeriod:string = arTimePeriodOptions[0].id;
  // selectedPeriodName:string = arTimePeriodOptions[0].name;

  private subProfile: Subscription;
  private subAuth: Subscription;

  constructor(
    private auth: AuthService,
    private me: UserService,
    private popup: PopupService,
    private notes: NotificationService,
  ) {}

  ngOnInit(): void {
    this.subProfile = this.me.profile.subscribe((profileState) => {
      this.hasProfileIssue = profileState.state === ProfileState.Failure;
    });
    this.subAuth = this.auth.authState$.subscribe((state) => {
      this.showLogin = state === AuthState.Non || (this.showLogin && state !== AuthState.Access);
      this.showDash = state === AuthState.Access;
    });
  }

  logout() {
    this.auth.clear();
  }

  ngOnDestroy() {
    this.subProfile.unsubscribe();
    this.subAuth.unsubscribe();
  }

  getMaxValue(dataSets: any) {
    let maxValue = 0;

    dataSets[0].data.forEach((value, i) => {
      let addSets = 0;

      dataSets.forEach((set) => {
        addSets += set.data[i];
      });

      maxValue = Math.max(maxValue, addSets);
      addSets = 0;
    });

    return maxValue;
  }

  getYAxis(maxValue: number) {
    return Math.ceil(maxValue / 100) * 100;
  }
}
