import { Injectable } from '@angular/core';
import {
  of, from, BehaviorSubject, Observable,
} from 'rxjs';
import {
  delay, switchMap, map, tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IPageResult } from 'src/app/entities/global';
import { IUser, IUserBulkAssign } from 'src/app/entities/user';
import { IRestaurant } from 'src/app/entities/restaurant';
import { IRestaurantSet } from 'src/app/entities/restaurant-set';
import { UserRole } from 'src/app/entities/user-role';
import { UserService } from 'src/app/services/api/user.service';
import { isEmail } from 'src/app/entities/util';

export interface IUpdateRequest {
  limit: number;
  offset: number;
  name: string;
  email: string;
}

export interface IAssignments {
  restaurants: IRestaurant[];
  restaurant_sets: IRestaurantSet[];
}

@Injectable({
  providedIn: 'root',
  })
export class UsersService {
  constructor(
    private http: HttpClient,
    private me: UserService,
  ) {

  }

  getUsers(
		offset: number = 0,
		limit: number = 16,
		name: string = '',
		role: UserRole|string = null
	) {
    const params = [['limit', limit], ['offset', offset]];
    if (name) {
			params.push([isEmail(name) ? 'email' : 'name', name]);
		}
    if (role) { params.push(['role', role]); }
    const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IPageResult<IUser>>(`api://users?${query}`);
  }

  addUser(user: any) {
    const isGroc = this.me.isA([UserRole.GroceryCampaignManager, UserRole.GroceryCampaignAgent]);
    return this.http.post<IUser>(`api://${isGroc ? 'grocery-' : ''}users/new`, user);
  }

  getUser(id: string) {
    const isGroc = this.me.isA([UserRole.GroceryCampaignManager, UserRole.GroceryCampaignAgent]);
    return this.http.get<IUser>(`api://${isGroc ? 'grocery-' : ''}users/${id}`);
  }

  setUser(id: string, user: any) {
    delete user.active;
    const isGroc = this.me.isA([UserRole.GroceryCampaignManager, UserRole.GroceryCampaignAgent]);
    return this.http.patch<IUser>(`api://${isGroc ? 'grocery-' : ''}users/${id}`, user);
  }

  undelUser(id: string) {
    const isGroc = this.me.isA([UserRole.GroceryCampaignManager, UserRole.GroceryCampaignAgent]);
    return this.http.patch<IUser>(`api://${isGroc ? 'grocery-' : ''}users/${id}?reactivate=yes`, {});
  }
  delUser(id: string, hard = false) {
    const isGroc = this.me.isA([UserRole.GroceryCampaignManager, UserRole.GroceryCampaignAgent]);
    return this.http.delete<IUser>(`api://${isGroc ? 'grocery-' : ''}users/${id}${hard ? '?hard_delete=yes' : ''}`);
  }

  inviteUser(user_id: string, user_type: 'grocery' | 'restaurant' = 'restaurant') {
    return this.http.post(`api://user-invitations/${user_id}?user_type=${user_type}`, {});
  }

  getAssignments(id: string) {
    return this.http.get<IAssignments>(`api://user-assignments/${id}`);
  }
  setAssignments(id: string, restaurant_set_ids: string[] = [], restaurant_ids: string[] = []) {
    return this.http.post<IAssignments>(`api://user-assignments/${id}`, { restaurant_set_ids, restaurant_ids });
  }

  bulkSetAssignments(csvFile: string) {
    return this.http.post<IUserBulkAssign>('api://user-assignments/', { csvFile });
  }
}
