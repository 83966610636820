import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface IContextMenu {
  open: () => void;
  containsElement: (elm: any) => boolean;
  close: () => void;
}

@Injectable({
  providedIn: 'root',
  })
export class ContextMenuService {
  private _id = 0;

  private _contextMenus: Map<string, IContextMenu> = new Map();
  private _openContextMenuId: string;
  private _openContextMenu: IContextMenu;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  get id() {
    return `cm${(++this._id).toString()}`;
  }

  registerContextMenu(id: string, contextMenu: IContextMenu) {
    if (this._contextMenus.has(id)) { throw Error(`ContextMenu ${id} already registered.`); }
    this._contextMenus.set(id, contextMenu);
    // console.log("Registered ContextMenu", id);
  }
  deregisterContextMenu(id: string) {
    if (!this._contextMenus.has(id)) { throw Error(`ContextMenu ${id} is not registered.`); }
    this._contextMenus.delete(id);
    // console.log("Deregistered ContextMenu", id);

    if (this._openContextMenuId === id) {
      this._openContextMenu = null;
      this._openContextMenuId = null;
    }
  }

  onAppClick(e: Event) {
    if (!this._openContextMenu) { return; } // console.log("No context menu open.");
    if (this._openContextMenu.containsElement(e.target)) {
      // console.log(`Clicked INSIDE open context menu ${this._openContextMenuId}`);
    } else {
      // console.log(`Clicked OUTSIDE open context menu ${this._openContextMenuId}`);
      e.stopPropagation();
      e.preventDefault();
    }
    setTimeout(() => {
      // prevent the double event when clicking on an input label
      // this.document.body.classList.remove('lock-screen');
      // console.log('unlocking screen', 'contextMenu');
      if (!this._openContextMenu) { return; }
      this._openContextMenu.close();
      this._openContextMenu = null;
      this._openContextMenuId = null;
    }, 0);
  }

  openContextMenu(id: string) {
    if (!this._contextMenus.has(id)) { throw Error(`ContextMenu ${id} is not registered.`); }
    if (id === this._openContextMenuId) { return; } // already open
    const contextMenu = this._contextMenus.get(id);
    if (this._openContextMenu) {
      this._openContextMenu.close();
    }
    setTimeout(() => {
      contextMenu.open();
      // this.document.body.classList.add('lock-screen');
      // console.log('locking screen', 'contextMenu');
      this._openContextMenu = contextMenu;
      this._openContextMenuId = id;
    }, 0);
  }
}
