<!-- <div class="page-toolbar analytic-toolbar flex">
  <div *ngIf="!loading&&selected" class="col flex">
    <div *ngIf="selected.logo" class="res-img">
      <img [src]="selected.logo" alt="">
    </div>

    <div class="toolbar-address" (click)="showList()">

			<div class="flex">
				<h6>{{selected.name}}</h6>
				<button class="btn-switch" id="btnSwitchRestaurant" userColor>Switch Restaurant</button>
			</div>
			<div class="flex">
				<address><span class="mdi mdi-map-marker"></span>{{selected.street_number}} {{selected.street_name}}, {{selected.suburb}}</address>
				<div class="rating-stars" *ngIf="selected.food_rating">
          <span [class.full]="selected.food_rating >= 0.5"></span>
          <span [class.full]="selected.food_rating >= 1.5"></span>
          <span [class.full]="selected.food_rating >= 2.5"></span>
          <span [class.full]="selected.food_rating >= 3.5"></span>
          <span [class.full]="selected.food_rating >= 4.5"></span>
        </div>
        <div class="rating-desc" *ngIf="selected.food_rating">
          {{selected.food_rating.toFixed(1)}} ({{selected.food_ratings_count | restaurantRatingCount}} ratings)
        </div>
			</div>

    </div>
  </div>
  <div class="btn-group">
    <a *appMayView="['promotion_create']" class="btn secondary" (click)="logEvent_CreatePromo()" [routerLink]="['/promos','new']" id="lnkNew">
        Create promotion <span class="mdi mdi-plus"></span>
    </a>
</div> -->
<div *ngIf="showLogin" class="vh-100">
  <app-login></app-login>
</div>

<div *ngIf="showDash">

  <div class="no-content" *appMayView="['user_view','grocery_user_view']">
    <div class="nc-image"><img src="assets/no-user.png"></div>
    <h5>Invite people to Restaurant Portal</h5>
    <p>When you invite your team members to use Restaurant Portal they will appear on this page.</p>
    <div class="btn-group" *appMayView="['user_invite','grocery_user_invite']">
        <a class="btn primary" routerLink="/admin/user/new" id="lnkInviteUser">Invite someone</a>
    </div>
  </div>

  <div class="no-content" *ngIf="hasProfileIssue">
    <div class="nc-image"><img src="assets/no-user.png"></div>
    <h5>Your profile is incomplete.</h5>
    <p>Please contact the person who created your account.</p>
    <div class="btn-group">
      <button class="btn primary" (click)="logout()">Log out</button>
    </div>
  </div>

</div>
