import {
  Component, OnInit, OnDestroy, HostListener, Inject, PLATFORM_ID,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {
  Subscription,
  Observable,
  merge,
  BehaviorSubject,
  of,
  combineLatest,
} from 'rxjs';
import { isPlatformServer } from '@angular/common';

import {
  Router, NavigationEnd, ActivatedRoute, NavigationStart, NavigationCancel,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  map, filter, mergeMap, delay, startWith, pairwise, switchMap, tap, catchError,
} from 'rxjs/operators';
import {
  animate, style, transition, trigger,
} from '@angular/animations';
import { FirebaseAnalyticsService } from './services/firebase_analytics.service';

import { AuthService, AuthState } from './services/auth.service';
import { ScrollAssistService } from './services/scroll-assist.service';
import { ContextMenuService } from './modules/context-menu/contextMenu.service';
import { OnlineService } from './services/online.service';
import { ApiHttpInterceptorService } from './services/http-interceptors/api-http-interceptor.service';
import { NotificationService, IToast } from './services/notification.service';
import { UserService, ProfileState, IProfileState } from './services/api/user.service';
import { BrazeService } from './modules/braze/braze.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
  trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(0, style({ opacity: 1 })),
      ]),
    transition('* => void', [
      animate(800, style({ opacity: 0 })),
      ]),
    ]),
  ],
  })
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private auth: AuthService,
    private user: UserService,
    private scroll: ScrollAssistService,
    private online: OnlineService,
    private contextMenu: ContextMenuService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private api: ApiHttpInterceptorService,
    private notes: NotificationService,
		private ngFA: FirebaseAnalyticsService,
		private braze: BrazeService,
  ) {
    this.online$ = this.online.status$.pipe(delay(10));
    this.toasts$ = this.notes.toast$;
    this.updateAvailalbe$ = this.notes.newVersionNotice$;
  }

  blueBg$ = combineLatest([this.auth.authState$, this.router.events]).pipe(
    map(([auth]) => auth === AuthState.Non && this.router.url === '/'),
  );

  online$: Observable<boolean>;
  updateAvailalbe$: Observable<boolean>;
  loadProgressWidth = 0; // 0: no loading, >0: loading
  toasts$: Observable<IToast[]>;
  showNavForSession = false;
  showNavForRoute = false;
  showLogin = false;
  showCards = false;
  showVerifyingProfile = false;
  showVerifyingProfile$: BehaviorSubject<boolean>;
  showRestaurantSelect = false;
  hideCreatePromoBtn = false;
  unviewedCardCount = 0;
  routeTabs: {}[] = null;
  tabBarButton: string;
  // activeRoute$: any;

  loading$: Observable<boolean>;
  loadingGlobal$;

  private subAuth: Subscription;
  private subProfile: Subscription;
  private subApiProgress: Subscription;
  private subRouteEvents: Subscription;

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    this.contextMenu.onAppClick(event);
  }

  ngOnInit() {
    const apiRequests$ = this.api.requestsInProgress.pipe(
      delay(10),
      startWith(0),
      pairwise(),
    );
    this.subApiProgress = apiRequests$.subscribe(([requestsInProgressPrev, requestsInProgress]) => {
      const remaining = 100 - this.loadProgressWidth;
      if (requestsInProgress === 0) {
        this.loadProgressWidth = 100 - Math.floor(remaining * 0.5);
        setTimeout(() => { this.loadProgressWidth = 0; }, 200);
      } else if (requestsInProgressPrev < requestsInProgress) { // a call started
        this.loadProgressWidth = 100 - Math.floor(remaining * 0.9);
      } else { // a call completed
        this.loadProgressWidth = 100 - Math.floor(remaining * 0.7);
      }
    });

    const navStart$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      // if route is users + search params change don't emit loader
      // to prevent loader from covering the text input
      // any other route will emit loader
      map((e: NavigationStart) => {
        if (e.url.indexOf('/admin/users') > -1 && e.url.length > 12) return false;
        return true;
      }),
    );
    const loadingEnd$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd || e instanceof NavigationCancel),
      delay(300), // allow api requests to start after navigation ended
      switchMap((e) => this.api.requestsInProgress),
      filter((count) => count === 0),
      map((count) => count !== 0), // don't show loading if zero
    );

    this.loadingGlobal$ = isPlatformServer(this.platformId) ? of(true)
      : merge(of(true), this.user.profile.pipe(
        catchError((er) => of(null)),
        // now we'll assume in case of a valid profile that some followup requests will be made
        map((v) => v?.state === ProfileState.Pending || v.state === ProfileState.Ready),
      ), loadingEnd$).pipe(
        delay(0),
      );

    this.loading$ = merge(navStart$, loadingEnd$).pipe(
      delay(0),
    );

    this.subAuth = this.auth.authState$.subscribe((state) => {
      this.showNavForSession = state === AuthState.Access;
      this.showLogin = state === AuthState.Non;
    });
    this.subProfile = this.user.profile.subscribe((profileState) => {
      this.showVerifyingProfile = profileState.state === ProfileState.Pending;
    });

    this.subRouteEvents = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap((e: NavigationEnd) => {
          if (e.url.indexOf('payments') > -1) {
            setTimeout(() => { this.hideCreatePromoBtn = true; }, 0);
          }
          if (e.url.indexOf('incomingreferrer') > -1) {
            let mpUrl = e.url;
            mpUrl = mpUrl.replace('/?marketplace_incomingreferrer=', '');
            this.ngFA.logEvent('view_login_pages', { name: `marketplace_${mpUrl}` });
          }
        }),
        map((e) => this.route),
        tap((route) => {
          const { tabs, createBtn } = route.firstChild.snapshot.data;
          this.tabBarButton = createBtn;
          if (tabs && !route.firstChild.firstChild.snapshot.data.hideTabBar) {
            this.routeTabs = tabs;
          } else {
            this.routeTabs = null;
          }
        }),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data.pipe(map((data) => ({ data, comp: route.component })))),
      )
      .subscribe(({ data, comp }) => {
        if (data && 'title' in data && data.title) {
          this.title.setTitle(`Mr D Food Restaurant Portal | ${data.title}`);
          this.ngFA.logEvent('screen_view', { firebase_screen: data.title, firebase_screen_class: 'AppComponent' });
        } else {
          this.title.setTitle('Mr D Food Restaurant Portal');
          this.ngFA.logEvent('screen_view', { firebase_screen: 'HOME', firebase_screen_class: 'AppComponent' });
        }
        if (data.title?.toLowerCase() === 'dashboard') {
          this.user.addSurveyScript(true);
        } else {
          this.user.addSurveyScript(false);
        }
        this.showNavForRoute = !(data?.hideNav);
        this.showRestaurantSelect = data?.showRestaurantSelect;
        this.hideCreatePromoBtn = !!(data?.hideCreatePromoBtn);
      });

    this.meta.updateTag({ name: 'description', content: 'Log in to Restaurant Portal from Mr D Food. Market your restaurant with promotions, make informed decisions using data.' });
  }

  ngOnDestroy() {
    if (this.subAuth) {
      this.subAuth.unsubscribe();
      this.subAuth = null;
    }
    if (this.subProfile) {
      this.subProfile.unsubscribe();
      this.subProfile = null;
    }
    if (this.subApiProgress) {
      this.subApiProgress.unsubscribe();
      this.subApiProgress = null;
    }
    if (this.subRouteEvents) {
      this.subRouteEvents.unsubscribe();
      this.subRouteEvents = null;
    }
  }
}
