import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Type,
  EmbeddedViewRef,
  ComponentRef,
} from '@angular/core';
import { DialogModule } from './dialog.module';
import { DialogComponent } from './dialog.component';
import { DialogInjector } from './dialog-injector';
import { DialogConfig } from './dialog-config';
import { DialogRef } from './dialog-ref';

@Injectable({
  providedIn: DialogModule,
  })
export class DialogService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public open(componentType: Type<any>, config: DialogConfig) {
    const { dialogRef, componentRef } = this.appendDialogComponentToBody(config);
    componentRef.instance.childComponentType = componentType;
    return dialogRef;
  }

  private appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    dialogRef.dismissible = config?.dismissible ?? false;
    dialogRef.className = config.className || 'default-style';
    dialogRef.title = config?.title || null;
    map.set(DialogRef, dialogRef);

    const sub = dialogRef.afterClosed.subscribe(() => {
      // eslint-disable-next-line no-use-before-define
      this.removeDialogComponentFromBody(componentRef);
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map));

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    componentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody(componentRef);
    });

    return { dialogRef, componentRef };
  }

  private removeDialogComponentFromBody(dialogComponentRef: ComponentRef<DialogComponent>) {
    this.appRef.detachView(dialogComponentRef.hostView);
    dialogComponentRef.destroy();
  }
}
