import {
  Directive,
  ElementRef,
  Input, TemplateRef, OnInit, OnDestroy, ViewContainerRef, EmbeddedViewRef, Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ContextMenuService, IContextMenu } from './contextMenu.service';

@Directive({
  selector: '[contextMenu]',
  })
export class ContextMenuDirective implements OnInit, OnDestroy, IContextMenu {
  #id: string;
  #added = false;
  #embeddedViewRef: EmbeddedViewRef<any>;

  @Input()
  set contextMenu(id: string) {
    this.#id = id;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private elementRef: ElementRef,
    private viewContainer: ViewContainerRef,
    private serv: ContextMenuService,
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  ngOnInit() {
    this.serv.registerContextMenu(this.#id, this);
  }

  open() {
    if (!this.#added) {
      this.#embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      this.#added = true;
      // console.log(`ContextMenu ${this.#id} OPEN.`);
    }
  }

  containsElement(elm: any) {
    // console.log(this.#embeddedViewRef.rootNodes.find(n => n.contains(elm)));
    if (!this.#embeddedViewRef) { return false; }
    return !!this.#embeddedViewRef.rootNodes.find((n) => n.contains(elm));
  }

  close() {
    if (this.#added) {
      this.#embeddedViewRef = null;
      this.viewContainer.clear();
      this.#added = false;
      this.document.body.parentElement.classList.remove('ios');
      // console.log(`ContextMenu ${this.#id} CLOSED.`);
    }
  }

  ngOnDestroy() {
    this.serv.deregisterContextMenu(this.#id);
  }
}
