/* eslint-disable no-param-reassign */
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

const PREFIX = 'ss/';

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
    // everything except Firefox
      e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
        // acknowledge QuotaExceededError only if there's something already stored
        && (storage && storage.length !== 0);
  }
}

@Injectable({
  providedIn: 'root',
  })
export class StoreService {
  private hasLS = false;

  private changesSubject =
    new Subject<{key: string, value: string, oldValue: string, external?: boolean}>();
  change$ = this.changesSubject.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.hasLS = storageAvailable('localStorage');
      window.onstorage = (e: StorageEvent) => {
        this.changesSubject.next({
          key: e.key, value: e.newValue, oldValue: e.oldValue, external: true,
        });
      };
    }
  }

  get hasStorage(): boolean {
    return this.hasLS; // add additional storage checks
  }

  set(key: string, value: string) {
    key = `${PREFIX}${key}`;
    let oldValue: string;
    if (this.hasLS) {
      oldValue = window.localStorage.getItem(key);
      window.localStorage.setItem(key, value);
    }
    this.changesSubject.next({ key, value, oldValue });
  }

  get(key: string) {
    key = `${PREFIX}${key}`;
    if (this.hasLS) {
      return window.localStorage.getItem(key);
    }
    return null;
  }

  getValueObservable(key:string):Observable<{value:string, oldValue:string, external?:boolean}> {
    key = `${PREFIX}${key}`;
    return this.changesSubject.pipe(
      filter((v) => v.key === key),
      map((v) => ({ oldValue: v.oldValue, value: v.value, external: v.external })),
    );
  }

  remove(key: string) {
    key = `${PREFIX}${key}`;
    let oldValue: string;
    if (this.hasLS) {
      oldValue = window.localStorage.getItem(key);
      window.localStorage.removeItem(key);
    }
    this.changesSubject.next({ key, value: null, oldValue });
  }
}
