import { Component, OnInit } from '@angular/core';
import { UserService, ProfileState, IProfileState } from 'src/app/services/api/user.service';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  })
export class ProfileComponent implements OnInit {
  state$: Observable<IProfileState>;

  constructor(
    private user: UserService,
  ) {
    this.state$ = this.user.profile;
  }

  ngOnInit(): void {
  }
}
