import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IBCap } from 'src/app/entities/budget_cap';
import { numberOnly } from 'src/app/entities/util';
import { DialogConfig } from 'src/app/modules/dialog/dialog-config';
import { DialogRef } from 'src/app/modules/dialog/dialog-ref';
import { PromosService } from 'src/app/modules/promos/services/promos.service';

export interface IDialogConfigData {
  title?: string;
  superTitle?: string;
  message: string | string[];
  okayText?: string;
  budgetCapData?: IBCap;
  isRestaurants?: boolean;
  restaurants?: any;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  })
export class AlertComponent {
  title: string; // optional
  superTitle: string;
  messages: string[];
  isBudgetCapActive: boolean = false;
  okayText = 'Okay';
  isListVisible: boolean = true;
  @Output() refreshList = new EventEmitter<boolean>();
  // @Output() budgetCapTracker = new EventEmitter<number>();
  error: boolean = false;
  currBudgetCap: number = 0;
  newBudgetCap: any = 0;
  promoID = null;
  isRestaurants: boolean = false;
  restaurantList: any = [];
  error_bCapMsg: string = '';

  constructor(
    public config: DialogConfig<IDialogConfigData>,
    public dialog: DialogRef<boolean>,
    private promoService: PromosService,
    private router: Router,
  ) {
    this.title = config.data.title;
    this.superTitle = config.data.superTitle;
    const msg = config.data.message;
    this.messages = Array.isArray(msg) ? msg : [msg];
    if (config.data.okayText) { this.okayText = config.data.okayText; }
    if (config.data.budgetCapData) {
      this.isBudgetCapActive = config.data.budgetCapData.bcapActive;
      this.currBudgetCap = config.data.budgetCapData.bcapAmount;
      this.promoID = config.data.budgetCapData.uuid;
    }
    if (config.data.isRestaurants) {
      this.isRestaurants = true;
      this.restaurantList = config.data.restaurants;
    }
  }

  complete(): void {
    this.dialog.close(true);
    this.refreshList.emit(true);
  }

  cancel(): void {
    this.dialog.close(false);
  }

  updateBudgetCap() {
    // eslint-disable-next-line no-unneeded-ternary
    this.promoService.updateCampaign(this.promoID, { budget_cap_amount: +this.newBudgetCap })
      .subscribe((res) => {
        this.dialog.close();
        this.promoService.updateFormStateSub();
      }, (err) => {
        this.error = true;
        const errMsg = err.error.error.developer_message;
        const getAmt = errMsg.substring(errMsg.lastIndexOf('R'), errMsg.length);
        if (errMsg.indexOf('increase amount') > -1) {
          this.error_bCapMsg = `The increase amount must be <strong>greater</strong> than ${getAmt}`;
        } else if (errMsg.indexOf('new budget cap amount') > -1) {
          this.error_bCapMsg = `The new budget cap amount must be <strong>greater</strong> than ${getAmt}`;
        } else {
          this.error_bCapMsg = err.error.error.developer_message;
        }
      });
  }

  checkNumber(event) {
    return numberOnly(event);
  }
}
