/* eslint-disable no-prototype-builtins */
import {
  Directive, ElementRef, OnInit, OnDestroy, Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileState, UserService } from 'src/app/services/api/user.service';
import { hue2col } from '../pipes/email-color.pipe';

@Directive({
  selector: '[userColor]',
  })
export class ProfileColorDirective implements OnInit, OnDestroy {
  /**
   * A comma separated list of element style properties to be assigned the color.
   * Possible properties include: 'color', 'backgroundColor', 'borderColor', etc.
   * Default is 'color'.
   */
  @Input() userColor = 'color';

  private subProfile: Subscription;

  constructor(
    private el: ElementRef,
    private user: UserService,
  ) {}

  ngOnInit() {
    this.subProfile = this.user.profile.subscribe((p) => {
      let color: string;
      const isMasonUncomfortableWithDynamicProfileColourUsage = true;

      if (isMasonUncomfortableWithDynamicProfileColourUsage || p.state !== ProfileState.Ready) {
        color = '#6ECFF5'; // temporary colour until we can limit range to 'none luminous' colours
        // color = '#cccccc';
      } else {
        color = p.profile.email ? hue2col(p.profile.email) : '#cccccc';
      }

      const atts = this.userColor.split(',');
      atts.forEach((att) => {
        this.el.nativeElement.style[att] = color;
      });
    });
  }

  ngOnDestroy() {
    this.subProfile.unsubscribe();
  }
}
