import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { StoreService } from './store.service';

export enum AuthState {
  Non = 0,
  Pending = 1,
  Access = 2,
}

const KEY_TYPE = 'authType';
export const KEY_AUTH = 'authToken';
const KEY_REFRESH = 'authRefreshToken';
const KEY_EXPIRY = 'authExpiry';
const ANALYTICS_PROMO_BANNER_DISMISSED = 'dismissedFirstTimePromoBanner';

@Injectable({
  providedIn: 'root',
  })
export class AuthService {
  refreshInProgress = false;

  private authStateSubject = new BehaviorSubject<AuthState>(AuthState.Pending);
  authState$ = this.authStateSubject.asObservable();

  set type(value: string) {
    if (value) {
      this.store.set(KEY_TYPE, value);
    } else {
      this.store.remove(KEY_TYPE);
    }
  }
  get type(): string {
    return this.store.get(KEY_TYPE);
  }

  set token(value: string) {
    if (value) {
      this.store.set(KEY_AUTH, value);
    } else {
      this.store.remove(KEY_AUTH);
    }
  }
  get token(): string {
    return this.store.get(KEY_AUTH);
  }

  set refreshToken(value: string) {
    if (value) {
      this.store.set(KEY_REFRESH, value);
    } else {
      this.store.remove(KEY_REFRESH);
    }
  }
  get refreshToken(): string {
    return this.store.get(KEY_REFRESH);
  }

  set expiryUnixMin(value: number) {
    if (value) {
      this.store.set(KEY_EXPIRY, value.toString());
    } else {
      this.store.remove(KEY_EXPIRY);
    }
  }
  get expiryUnixMin(): number {
    const sValue = this.store.get(KEY_EXPIRY);
    return sValue ? parseInt(sValue, 10) : 0;
  }

  get authRefreshExpired() {
    return this.expiryUnixMin < Math.round(Date.now() * 0.001);
  }

  loginStarted() {
    this.authStateSubject.next(AuthState.Pending);
  }
  loginFailed() {
    this.authStateSubject.next(AuthState.Non);
  }

  constructor(
    private store: StoreService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (store.hasStorage) {
      this.store.getValueObservable(KEY_AUTH)
        .pipe(
          startWith({ oldValue: null, value: this.token, external: false }),
        )
        .subscribe((subscriberStore) => {
          if (subscriberStore.external) {
            this.document.location.reload();
          } else {
            this.authStateSubject.next(subscriberStore.value ? AuthState.Access : AuthState.Non);
          }
        });
    }
  }

  clear() {
    this.store.remove(KEY_TYPE);
    this.store.remove(KEY_AUTH);
    this.store.remove(KEY_REFRESH);
    this.store.remove(KEY_EXPIRY);
    this.store.remove(ANALYTICS_PROMO_BANNER_DISMISSED);
  }
}
