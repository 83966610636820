import { Pipe, PipeTransform } from '@angular/core';
import { UserRole, userRoles, IUserRole } from 'src/app/entities/user-role';

@Pipe({
  name: 'role',
  })
export class RolePipe implements PipeTransform {
  transform(userRole: IUserRole | UserRole): string {
    let roleId;
    if (!userRole) {
      return '[unknown role]';
    }
    if (typeof (userRole) === 'string') {
      roleId = userRole;
    } else if ('id' in userRole) {
      roleId = userRole.id;
    } else {
      return `[${userRole}]`;
    }
    const role = userRoles.find((r) => r.id === roleId);
    if (role) {
      return role.name;
    }
    return `[${userRole}]`;
  }
}
