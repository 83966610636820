import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { Permission } from 'src/app/entities/user-permission';
import { UserRole } from 'src/app/entities/user-role';
import { UserService, ProfileState } from '../api/user.service';

@Injectable({
  providedIn: 'root',
  })
export class PermissionGuard implements CanActivate {
  constructor(
    private user: UserService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: object,
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log("PermissionGuard.canActivate")
    if (isPlatformServer(this.platform)) { return true; }
    // console.log("PermissionGuard.canActivate BROWSER")

    const { url } = state;
    const reqPermissions = next.data.permissionsReq as Permission[];

    return this.user.profile.pipe(
      filter((profileState) => profileState.state !== ProfileState.Pending),
      map((profileState) => {
        if (profileState.profile?.role_name === UserRole.Admin) { return true; }
        if (reqPermissions?.length) {
          const perms = profileState.profile?.permissions ?? [];
          return !!reqPermissions.find((r) => perms.includes(r));
        }
        return true;
      }),
      tap((allowed) => {
        if (!allowed) { this.router.navigate(['/']); }
      }),
    );
  }
}
