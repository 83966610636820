import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  Observable, fromEvent, merge, of,
} from 'rxjs';
import { map, startWith, shareReplay } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
  })
export class OnlineService {
  #online$: Observable<boolean>;
  get status$() {
    return this.#online$;
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
  ) {
    if (isPlatformServer(this.platform)) {
      this.#online$ = of(true);
      return;
    }
    this.#online$ = merge(
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false)),
    ).pipe(
      startWith(navigator.onLine),
      shareReplay(1),
    );
  }
}
