<div class="toolbar">
	<div class="wrapper flex">
        <div class="tabs">
            <a *ngFor="let tab of _tabData"
                [queryParams]="{as:viewAsUserId,for:viewForRestaurantSetId}"
                routerLinkActive="active"
                id="lnkPromosCurrent"
                [routerLink]="tab.route">
                <div>{{ tab.title }}</div>
            </a>
            <a class="mobileButton promoAddIcon"
                (click)="logEvent_CreatePromo()"
                [routerLink]="['/promos','new']"
                [queryParams]="{as:viewAsUserId,for:viewForRestaurantSetId}"
                id="lnkNew"
                *ngIf="isPromosActive">
                <span class="mdi mdi-plus"></span>
            </a>
            <ng-container *appMayView="['promotion_create']">
                <a class="btn secondary promoBtnLarge"
                    (click)="logEvent_CreatePromo()"
                    [routerLink]="['/promos','new']"
                    [queryParams]="{as:viewAsUserId,for:viewForRestaurantSetId}"
                    id="lnkNew"                 
                    *ngIf="!hidePromoBtn">
                    Create promotion <span class="mdi mdi-plus"></span>
                </a>
            </ng-container>
        </div>
    </div>
</div>
