import {
  Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef,
} from '@angular/core';
import { Permission } from 'src/app/entities/user-permission';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/api/user.service';
import { UserRole } from 'src/app/entities/user-role';

@Directive({
  selector: '[appMayView]',
  })
export class MayViewDirective implements OnInit, OnDestroy {
  #added = false;
  #allAccess = false;
  #currentPermissions: Permission[];
  #permissions: Permission[] = [];
  #requiresAll = false;
  #sub: Subscription;

  @Input()
  set appMayView(val: Permission[]) {
    this.#permissions = val;
    this.updateView();
  }

  @Input()
  set appMayViewAll(val: boolean) {
    this.#requiresAll = val;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private user: UserService,
  ) {

  }

  ngOnInit() {
    this.#sub = this.user.profile.subscribe((profileState) => {
      this.#allAccess = profileState.profile?.role_name === UserRole.Admin;
      this.#currentPermissions = profileState.profile?.permissions;
      this.updateView();
    });
  }

  private updateView() {
    let may = false;
    if (this.#allAccess) {
      may = true;
    } else if (!this.#currentPermissions?.length) {
      may = false;
    } else if (this.#requiresAll) {
      may = this.#permissions.filter(
        (req) => this.#currentPermissions.includes(req),
      ).length === this.#permissions.length;
    } else {
      may = !!this.#permissions.find((req) => this.#currentPermissions.includes(req));
    }
    if (may && !this.#added) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.#added = true;
    } else if (!may && this.#added) {
      this.viewContainer.clear();
      this.#added = false;
    }
  }

  ngOnDestroy() {
    if (this.#sub) { this.#sub.unsubscribe(); }
  }
}
