import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse,
} from '@angular/common/http';
import {
  Observable, throwError, of, EMPTY,
} from 'rxjs';
import {
  switchMap, catchError, tap, filter, take, skip,
} from 'rxjs/operators';

import { isPlatformServer } from '@angular/common';
import { AuthService, AuthState } from '../auth.service';

@Injectable({
  providedIn: 'root',
  })
export class AuthHttpInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    private auth: AuthService,
  // eslint-disable-next-line no-empty-function
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { token } = this.auth;

    if (this.xapi(req)) {
      return this.xauth(req, next);
    }
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });

      return next.handle(req).pipe(
        catchError((er) => {
          if (er instanceof HttpErrorResponse && er.status === 401) {
            if (this.auth.refreshInProgress) {
              // we now depend on auth state change to change before retrying
              return this.auth.authState$.pipe(
                skip(1), // skip the replay
                filter((state) => state === AuthState.Access), // retry when refresh is complete
                take(1),
                switchMap(() => {
                  const { token } = this.auth; // get the refreshed token
                  if (token) {
                    req = req.clone({
                      headers: req.headers.set('Authorization', `Bearer ${token}`),
                    });
                    return next.handle(req); // this is the official retry with the now new token
                  }
                  return throwError('Token refresh failed.');
                }),
              );
            }
            if (this.auth.authRefreshExpired) {
              this.auth.clear();
              console.log('Refresh expired. Auth cleared.');
              this.reloadRoute();
              return of(null);
            }
            if (!this.auth.refreshToken) {
              console.log('Session expired. Auth already cleared.');
              this.reloadRoute();
              return of(null);
            }
            return this.updateRequestToRefreshToken(req, next);
          }
          // console.log("Http error response", er )
          if (er instanceof HttpErrorResponse) {
            // console.warn("Auth interceptor", JSON.stringify(er.error.error));
            return throwError(er);
          }
          return throwError(er); // this error is not the responsability of the interceptor
        }),
      );
    }

    return next.handle(req);
  }

  private updateRequestToRefreshToken(
    req: HttpRequest<any>, next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // const prevReq = req.clone(); // save failed request for later if refresh was successful
    const token = this.auth.refreshToken;

    // create token request (not clone) in order to avoid inheriting weird properties
    // e.g. reportProgress: true and responseType: 'blob' from download report req
    const tokenReq = new HttpRequest(
      'POST',
      'api://token',
      { grant_type: 'refresh_token', refresh_token: token },
      { headers: req.headers.delete('Authorization') },
    );

    // req = req.clone({
    //   method: 'POST',
    //   url: 'api://token',
    //   body: { grant_type: 'refresh_token', refresh_token: token },
    //   headers: req.headers.delete('Authorization'),
    //   reportProgress: false, // initial fix for req inheriting weird props
    //   responseType: 'json',
    // });

    // return next.handle(req).pipe(
    return next.handle(tokenReq).pipe(
      ($) => new Observable((subscriber) => {
        this.auth.refreshInProgress = true;
        const sub = $.subscribe(subscriber);
        return () => {
          sub.unsubscribe();
          this.auth.refreshInProgress = false; // FIXES https://mrdelivery.atlassian.net/browse/RSSP-1326
        };
      }),
      tap((e) => {
        if (e instanceof HttpResponse) {
          const res = e as HttpResponse<any>;
          this.auth.type = res.body.token_type;
          this.auth.token = res.body.access_token; // <- this triggers pending requests to retry
          this.auth.refreshToken = res.body.refresh_token;
          this.auth.expiryUnixMin = Math.round(Date.now() * 0.001) + res.body.expires_in * 60;
          this.auth.refreshInProgress = false;
        }
      }, () => {
        // refresh failed
        this.auth.clear();
        console.log('Refresh unsuccessful. Auth cleared.');
        this.reloadRoute();
      }),
      filter((e) => e instanceof HttpResponse),
      switchMap(() => {
        // req = prevReq.clone({ // reinstate initial request
        req = req.clone({ // add new token to initial request
          headers: req.headers.set('Authorization', `Bearer ${this.auth.token}`),
        });
        return next.handle(req);
      }),
    );
  }

  private reloadRoute() {
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate(['/'],{relativeTo:this.route});
    window.location.reload(); // works best to re-run route guards
  }

  private xapi(req: HttpRequest<any>): boolean {
    return req.url.startsWith('xapi://') || req.url.startsWith('img://');
  }
  private xauth(req: HttpRequest<any>, next: HttpHandler) {
    if (isPlatformServer(this.platform)) { return EMPTY; }

    const token = localStorage.getItem('_xapi_auth');
    if (token) {
      req = req.clone({
        headers: req.headers
          .set('Authorization', `Basic ${token}`)
          .set('x-api-key', '9b7a5675-3b46-42c9-91e8-fe9dfd9549f1'),
      // .set('x-client-version','999.0.0')
      });
    }

    return next.handle(req);
  }
}
