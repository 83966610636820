import {
  Injectable, Inject, PLATFORM_ID, Optional,
} from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,
} from '@angular/common/http';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import {
  Observable, throwError, TimeoutError, BehaviorSubject, EMPTY, timer,
} from 'rxjs';
import {
  switchMap, catchError, timeout, finalize, mergeMap, retryWhen,
} from 'rxjs/operators';

import { IErrorResponse } from 'src/app/entities/global';
import { trace } from '@angular/fire/performance';
import { environment } from 'src/environments/environment';
import { EnvService } from '../api/env.service';

// Taken from: https://stackblitz.com/edit/angular-cwnknr?file=app%2Frxjs-utils.ts
export const retryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
} = {}) => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((er, i) => {
    const retryAttempt = i + 1;
    if (!(er instanceof TimeoutError) || retryAttempt > maxRetryAttempts) {
      return throwError(er);
    }
    console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
    // retry after 1s, 2s, etc...
    return timer(retryAttempt * scalingDuration);
  }),
  // finalize(() => console.log('We are done!'))
);

@Injectable({
  providedIn: 'root',
  })
export class ApiHttpInterceptorService implements HttpInterceptor {
  #requestsInProgress = 0;
  #requestsInProgress$ = new BehaviorSubject(0);
  requestsInProgress = this.#requestsInProgress$.asObservable();

  constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object,
    private env: EnvService,
  // eslint-disable-next-line no-empty-function
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('self://')) {
      this.#requestsInProgress$.next(++this.#requestsInProgress);
      return this.env.value$.pipe(switchMap(() => {
        const apiReq = req.clone({
          url: req.url.replace('self://', `${this.document.location.origin}/`), // this might not work in hosted env
        });

        return next.handle(apiReq).pipe(
          finalize(() => {
            this.#requestsInProgress$.next(--this.#requestsInProgress);
          }),
        );
      }));
    }

    if (req.url.startsWith('xapi://') || req.url.startsWith('img://')) {
      if (isPlatformServer(this.platform)) { return EMPTY; }

      this.#requestsInProgress$.next(++this.#requestsInProgress);
      return this.env.value$.pipe(switchMap(() => {
        const apiReq = req.clone({
          // url: req.url.replace('xapi:/', environment.expApiHost).replace('img:/', 'https://image-api.qa.mrd.com'),
          url: req.url.replace('xapi:/', environment.expApiHost).replace('img:/', 'https://groceries-rssp-api.qa.mrd.com/images/groceries'),
        });
        return next.handle(apiReq).pipe(
          finalize(() => {
            this.#requestsInProgress$.next(--this.#requestsInProgress);
          }),
        );
      }));
    }

    if (req.url.startsWith('api://')) {
      if (isPlatformServer(this.platform)) {
        /* let's for now end the request immediately until we find use cases that depend oon data.
         TODO: maybe empty() is better */
        return EMPTY;
        // console.log("SSR API", req.url);
        // const apiReq = req.clone({
        //   url: req.url.replace('api:/', environment.ssrApiHost)
        // });
        // return next.handle(apiReq);
      }

      this.#requestsInProgress$.next(++this.#requestsInProgress);
      return this.env.value$.pipe(switchMap((env) => {
        const apiReq = req.clone({
          url: req.url.replace('api:/', env.api),
        });
        const nextSlash = req.url.indexOf('/', 6);
        const traceName = req.url.substring(6, nextSlash);
        const dontWait = req.method.toUpperCase() === 'GET';
        return next.handle(apiReq).pipe(
          // TODO: investigate how performance can be measured using the new firebase version
          // trace(`API__${req.method.toUpperCase()}__${traceName}`),
          // timeout(dontWait ? 6000 : 60000),
          retryWhen(
            retryStrategy({ maxRetryAttempts: dontWait ? 3 : 0 }),
          ), // long duration requests fail once
          catchError((er) => {
            if (er instanceof TimeoutError) {
              (er as any).error = ({ error: { friendly_message: 'This request took to long. Please try again.' } } as IErrorResponse);
            } else {
              // console.log("HttpErrorResponse?", er);
            }
            return throwError(er);
          }),
          finalize(() => {
            this.#requestsInProgress$.next(--this.#requestsInProgress);
          }),
        );
      }));
    }

    return next.handle(req);
  }
}
