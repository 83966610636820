import {
  Component, OnInit, OnDestroy, Inject,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import {
  filter, map, switchMap, tap,
} from 'rxjs/operators';

import { IErrorResponse } from 'src/app/entities/global';
import { setReactiveFormFieldErrors, getErMsgX } from 'src/app/entities/util';
import { UserService } from 'src/app/services/api/user.service';
import { Permission } from 'src/app/entities/user-permission';
import { UserRole } from 'src/app/entities/user-role';
import { BrazeService, EventName } from 'src/app/modules/braze/braze.service';
import { PopupService } from 'src/app/services/popup.service';
import { CofundedPromosService } from 'src/app/modules/cofunded-promos/cofunded-promos.service';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  })
export class LoginComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
  });

	showPassword: boolean = false;
  submitted = false;
  busy = false;
  error: string;
  errorGoogle: string;
  targetPath: string;

  private subRoute: Subscription;

  constructor(
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private ngFA: FirebaseAnalyticsService,
    private braze: BrazeService,
    private pop: PopupService,
    private cofunded: CofundedPromosService,
  ) {

  }

  ngOnInit(): void {
    this.ngFA.logEvent('view_login_screen');
    this.subRoute = this.route.queryParamMap.pipe(
      map((map) => map.get('target')),
    ).subscribe((targetPath) => {
      this.targetPath = targetPath;
    });

    this.route.fragment.pipe(
      filter((frags) => frags?.includes('tcs')),
      switchMap(() => this.pop.showTsAndCs()),
    ).subscribe(() => this.router.navigate(['.']));
  }

  onSubmit() {
    // console.log(this.form.value)
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.login();
  }

  loginGoogle() {
    this.errorGoogle = null;
  	this.ngFA.logEvent('tap_login_screen_google_button');
    this.busy = true;
    this.user.loginGoogle()
      .subscribe((res) => {
        this.ngFA.logEvent('login_completed_google');
        this.onLoginSuccess();
      }, (res: HttpErrorResponse) => {
        const body = res.error as IErrorResponse;
        this.errorGoogle = body.error?.friendly_message || "We didn't find an account with that email address.";
        this.busy = false;
        this.ngFA.logEvent('tap_login_errors_google', { code: res?.status ?? 0, message: getErMsgX(res) });
      });
  }

  private login() {
    this.error = null;
    this.ngFA.logEvent('tap_login_screen');
    this.busy = true;
    const { email, password } = this.form.value;
    this.user.login((email as string).trim(), password)
      .subscribe((res) => {
        this.ngFA.logEvent('login_completed');
        this.onLoginSuccess();
      }, (res: HttpErrorResponse) => {
        setReactiveFormFieldErrors(this.form, res);
        const body = res.error as IErrorResponse;
        this.error = body?.error?.friendly_message || 'Unable to log in.';
        this.busy = false;
        this.ngFA.logEvent('tap_login_errors', { code: res?.status ?? 0, message: getErMsgX(res) });
      });
  }

  private onLoginSuccess() {
		const toggleNpsSurvey = this.user.getNPSSurveyState().subscribe((data) => {
			if (data && data[0] === 'Y') {
				this.user.addNPSSurveyScript(); // add NPS survey script here
			}
		});
		
		const finManager = this.user.isA(UserRole.FinanceManager);
		const salesHead = this.user.isA(UserRole.SalesHead);
    this.busy = false;

    this.braze.logEvent(EventName.Login);
    this.cofunded.updateCoFundedPromos();

    if (this.targetPath) {
      this.location.replaceState(''); // clear query params
      setTimeout(() => {
        this.router.navigateByUrl(this.targetPath);
      }, 900);
    } else {
      let path: any[] = ['/'];
			if (this.user.isA(UserRole.Admin)) {
				path = ['/admin', 'users'];
      } else if (this.user.authorisedTo([Permission.GroupAnalyticsView])) {
				path = ['/group-dashboard'];
      } else if (finManager || salesHead) {
        path = ['/payments', 'earnings'];
      } else if (this.user.isA(UserRole.GroceryCampaignAgent)
      || this.user.isA(UserRole.GroceryCampaignManager)) {
        path = ['grocery-campaigns'];
      } else if (this.user.authorisedTo([Permission.AnalyticsView])) {
        path = ['/dashboard'];
      } else if (this.user.authorisedTo([Permission.UserView, Permission.GroceryUserView])) {
        path = ['/admin', 'users'];
      }			
      this.router.navigate(path);
    }
  }

  ngOnDestroy() {
    this.subRoute.unsubscribe();
  }
}
