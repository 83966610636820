import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPageResult } from 'src/app/entities/global';
import {
  IPromo, ICampaign, PromoType, IMenuDealCampaign, IPromotionCampaign, IBuy1Get1FreeCampaign,
} from 'src/app/entities/promo';
import { map, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

export interface IMinSpendValue {
  min_spend_value: number; // cents
  service_fee?: number; // cents
  // global_fee?: number; // cent (copied from global_service_fee)
}
export interface IServiceFees {
  entity: 'promotion' | string;
  type: 'discounted_delivery_fee_amount' | string;
  list: IMinSpendValue[];
  rssp_funded_delivery_fee?: number;
  global_service_fee: number; // cents
}

export enum GetCampaignMode {
  All = 'all',
  Performance = 'performance',
}

export enum GetCampaignPerformanceFilter {
  MenuDeal = 'menu_deal',
  FreeDelivery = 'free_delivery',
  FoodDiscount = 'food_discount',
  Buy1Get1Free = 'buy_1_get_1_free',
  HappyHour = 'happy_hour',
}

@Injectable({
  providedIn: 'root',
  })
export class PromosService {
  private formStateSubject = new BehaviorSubject<boolean>(false);
  formState$ = this.formStateSubject.asObservable();

  private perfCardSubject = new BehaviorSubject<boolean>(false);
  perfCardSubjectState$ = this.perfCardSubject.asObservable();

  updateFormStateSub() {
    this.formStateSubject.next(true);
  }

  updatePerfCardSubject() {
    this.perfCardSubject.next(true);
  }

  constructor(
    private http: HttpClient,
  ) { }

  getCampaigns(
    mode: GetCampaignMode,
    offset: number,
    limit: number,
    withAnalytics: boolean,
    filter: null,
    criteria?: {user_id?: string, restaurant_set_id?: string, restaurant_id?: string[]},
  ): Observable<IPageResult<IPromotionCampaign&IMenuDealCampaign>>;

  getCampaigns(
    mode: GetCampaignMode,
    offset: number,
    limit: number,
    withAnalytics: boolean,
    filter: GetCampaignPerformanceFilter.MenuDeal,
    criteria?: {user_id?: string, restaurant_set_id?: string, restaurant_id?: string[]},
  ): Observable<IPageResult<IMenuDealCampaign>>;

  getCampaigns(
    mode: GetCampaignMode,
    offset: number,
    limit: number,
    withAnalytics: boolean,
    filter: GetCampaignPerformanceFilter.Buy1Get1Free,
    criteria?: {user_id?: string, restaurant_set_id?: string, restaurant_id?: string[]},
  ): Observable<IPageResult<IBuy1Get1FreeCampaign>>;

  getCampaigns(
    mode: GetCampaignMode,
    offset: number,
    limit: number,
    withAnalytics: boolean,
    filter: GetCampaignPerformanceFilter.FreeDelivery | GetCampaignPerformanceFilter.FoodDiscount,
    criteria?: {user_id?: string, restaurant_set_id?: string, restaurant_id?: string[]},
  ): Observable<IPageResult<IPromotionCampaign>>;

  getCampaigns(
    mode: GetCampaignMode,
    offset: number,
    limit: number,
    withAnalytics: boolean,
    filter: GetCampaignPerformanceFilter,
    criteria?: {user_id?: string, restaurant_set_id?: string, restaurant_id?: string[]},
  ): Observable<IPageResult<IPromotionCampaign&IMenuDealCampaign&IBuy1Get1FreeCampaign>>;

  getCampaigns(
    mode: GetCampaignMode = GetCampaignMode.All,
    offset: number = 0,
    limit: number = 16,
    withAnalytics = false,
    filter: GetCampaignPerformanceFilter,
    criteria?: {user_id?: string, restaurant_set_id?: string, restaurant_id?: string[]},
  ) {
    const params: [string, string|number|boolean][] = [
      ['mode', mode],
      ['limit', limit],
      ['offset', offset],
      ['include_last_updated_by_admin_bogof', true],
    ];
    if (withAnalytics) { params.push(['include_analytics', true]); }
    if (filter) { params.push(['filter', filter]); }
    if (criteria) {
      if (criteria.user_id) { params.push(['user_id', criteria.user_id]); }
      if (criteria.restaurant_set_id) { params.push(['restaurant_set_id', criteria.restaurant_set_id]); }
      if (criteria.restaurant_id) { params.push(['restaurant_id', criteria.restaurant_id.join(',')]); }
    }
    const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IPageResult<ICampaign>>(`api://campaigns?${query}`).pipe(
      tap((page) => page.list.forEach((c) => c.restaurants = c.restaurants.filter((r) => !!r))),
    );
  }

  createCampaign(payload: any) {
    return this.http.post<IPromotionCampaign|IMenuDealCampaign|IBuy1Get1FreeCampaign>('api://campaigns/new', payload);
  }

  getCampaign(id: string) {
    return this.http.get<IPromotionCampaign|IMenuDealCampaign|IBuy1Get1FreeCampaign>(`api://campaigns/${id}`);
  }

  // CoFunded Promo - status update
  updateCampaign(id:string, payload:any) {
    return this.http.patch<any>(`api://campaigns/${id}`, payload);
  }

  deleteCampaign(id: string) {
    return this.http.delete<IPromotionCampaign|IMenuDealCampaign|IBuy1Get1FreeCampaign>(`api://campaigns/${id}`);
  }

  getPromos(campaignId: string, offset: number = 0, limit: number = 16, withAnalytics = false) {
    const params: [string, string|number|boolean][] = [
      ['limit', limit],
      ['offset', offset],
    ];
    if (withAnalytics) { params.push(['include_analytics', true]); }
    const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IPageResult<IPromo>>(`api://campaigns/${campaignId}/promotions?${query}`);
  }

  deletePromo(id: string) {
    return this.http.delete<IPromo>(`api://promotions/${id}`);
  }

  getMinSpendOptions(type: PromoType): Observable<IServiceFees> {
    return this.http.get<IServiceFees>(`api://service-fees?entity=promotion&type=${type}`).pipe(
      map((fees) => {
        let out: IMinSpendValue[];
        if (!fees.list?.length) {
          fees.list = [10, 15, 20].map((v) => ({
            min_spend_value: v * 1000,
          }));
        }
        fees.list.sort((a, b) => a.min_spend_value - b.min_spend_value);
        return fees;
      }),
      map((fees: IServiceFees) => {
        fees.list.sort((a, b) => a.min_spend_value - b.min_spend_value);
        return fees;
      }),
    );
  }

  getGlobalBudgetCapAmt(): Observable<number> {
    return this.http.get<any>('api://settings/minimum_budget_cap');
  }
}
