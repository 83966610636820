import { Permission } from './user-permission';

export enum UserRole {
  /**
   * The super user who's usage on the system is unrestricted.
   */
  Admin = 'rssp_admin',

  /**
   * Has visibility over a large area of brand, typically one or two provincial restaurant sets.
   */
  SalesHead = 'sales_head', // Mr D Food staff

  /**
   * Used internally by our account managers
   */
  AccountManager = 'sales_account_manager', // Mr D Food staff

  /**
   * DEPRECATED
   */
  Support = 'restaurant_support', // Mr D Food staff

  /**
   * Will typically see all the restaurants of his/her brand.
   */
  PartnerHead = 'restaurant_company_head',

  /**
   * Will have visibility over a hand full of restaurants, but mostly a single restaurant.
   */
  RestaurantOwner = 'restaurant_owner',

  /**
   * Will have visibility over a hand full of restaurants, but mostly a single restaurant.
   */
  RestaurantManager = 'restaurant_manager',

  /**
   * Will have visibility over a hand full of restaurants, but mostly a single restaurant.
   */
  RestManagerLesser = 'restaurant_lesser_manager',

  /**
   * Will have visibility over payments, reports and users menu items
   */
  FinanceManager = 'finance_manager',

  /**
   * Will have visibility of grocery campaigns and agents
   */
  GroceryCampaignManager = 'grocery_campaign_manager',

  /**
   * Will have visibility of grocery campaigns
   */
  GroceryCampaignAgent = 'grocery_campaign_agent',

  ExternalPartner = 'external_partner',
  BiUser = 'bi_user',

  /**
   * For Group Reporting Data (ALL restaurants available here)
   */
  GroupHead = 'group_head',	

  /**
   * Similar to Group Head but restricted to a list of IDs for rests in that franchise
   */
  FranchiseHead = 'franchise_head',		

}
export interface IUserRole {
  id: UserRole;
  name: string;
  mdiClass?: string;
  extraPermissions?: Permission[];
	showRestPopupList?: boolean;
}

/**
All roles except ones below: analytics > promos > billing > users > menu
Restaurant manager sees:     analytics > promos > billing > support > menu
Restaurant support sees:     users > admin > support > menu
 */
export const userRoles: IUserRole[] = [
  {
    id: UserRole.Admin,
    name: 'Admin',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
      Permission.AdminView,
    ],
  }, {
    id: UserRole.SalesHead,
    name: 'Sales Head',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
    ],
    showRestPopupList: true,
  }, {
    id: UserRole.AccountManager,
    name: 'Account Manager',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
    ],
  }, {
    id: UserRole.Support,
    name: 'Support',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
    ],
  }, {
    id: UserRole.PartnerHead,
    name: 'Restaurant Company Head',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
    ],
  }, {
    id: UserRole.RestaurantOwner,
    name: 'Restaurant Owner',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
    ],
  }, {
    id: UserRole.RestaurantManager,
    name: 'Restaurant Manager',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
    ],
  }, {
    id: UserRole.RestManagerLesser,
    name: 'Restaurant Report Admin',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.PromoView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
    ],
  }, {
    id: UserRole.FinanceManager,
    name: 'Finance Manager',
    mdiClass: '',
    extraPermissions: [
      Permission.UIViewEnv,
      Permission.AnalyticsView,
      Permission.UIViewBilling,
      Permission.UIViewSupport,
      Permission.UserInvite,
      Permission.UserView,
      Permission.FinanceView,
      Permission.FinanceCreate,
    ],
  }, {
    id: UserRole.GroceryCampaignManager,
    name: 'Grocery Campaign Manager',
  }, {
    id: UserRole.GroceryCampaignAgent,
    name: 'Grocery Campaign Agent',
  }, {
    id: UserRole.GroupHead,
    name: 'Restaurant Group Head',		
		// showRestPopupList: true,
  }, {
    id: UserRole.FranchiseHead,
    name: 'Restaurant Franchise Head',
  },
];
