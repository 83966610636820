import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  BehaviorSubject, combineLatest, Observable,
  Subject, timer, of, EMPTY,
} from 'rxjs';
import {
  map, tap, shareReplay, take, switchMap, startWith, filter, takeLast,
} from 'rxjs/operators';
import {
  PromoType, IUnredeemedCoFunded, ICoFundedCrudPageParams, CofundedCampaignStatus,
} from 'src/app/entities/promo';
import { RestaurantsService } from 'src/app/modules/admin/restaurants/restaurants.service';
import { UserRole } from 'src/app/entities/user-role';
import { UserService } from 'src/app/services/api/user.service';
import { ICofundedPromoCampaign } from './components/cofunded-campaign/cofunded-campaign.component';

@Injectable({
  providedIn: 'root',
  })
export class CofundedPromosService {
  readonly isBannerExpired$: Observable<any>;
  private isBannerExpiredSubject = new BehaviorSubject<boolean>(false);
  readonly coFundedPromos$: Observable<IUnredeemedCoFunded>;
  private coFundedPromoCountSubject = new BehaviorSubject<number>(0);
  readonly coFundedCount$: Observable<any>;
  readonly coFundedPercentageDisc$: Observable<any>;
  private coFundedPercentageDiscSubject = new BehaviorSubject<number>(0);
  coFundedRestaurantList: Array<number>;
  coFundedCrudParams: ICoFundedCrudPageParams;

  private subjectRefreshCoFundedPromos$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private restaurant: RestaurantsService,
    private me: UserService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {
    this.isBannerExpired$ = this.isBannerExpiredSubject.pipe(
      shareReplay(1),
    );
    this.coFundedCount$ = this.coFundedPromoCountSubject.pipe(
      shareReplay(1),
    );
    this.coFundedPercentageDisc$ = this.coFundedPercentageDiscSubject.pipe(
      shareReplay(1),
    );

    const timer$ = isPlatformBrowser(this.platform) ? timer(0, 1000 * 60 * 10) : of(1);

    combineLatest([
      timer$, // reload co-funded data every 10 minutes to stay up to date
      this.subjectRefreshCoFundedPromos$, // and on demand
    ]).pipe(
      switchMap(() => this.me.profile),
      filter((state) => !!state.profile && state.profile.role_name === UserRole.RestaurantOwner),
      switchMap((profile) => (profile ? this.getUnredeemedCoFundedPromos() : EMPTY)),
    ).subscribe((data) => {
      if (data.meta.total_unredeemed_cofunded_templates > 0) {
        const firstResult = data.unredeemed_cofunded_template_list[0];
        const listLength = data.unredeemed_cofunded_template_list.length;
        this.coFundedCrudParams = {
          start_date: firstResult.start_date,
          end_date: firstResult.end_date,
          itemCap: firstResult.template_vars.buy_1_get_1_free_item_limit,
          uuid: firstResult.uuid,
        };
        this.coFundedPromoCountSubject.next(listLength > 0 ? listLength : 0);
        this.coFundedRestaurantList = firstResult.restaurant_id_list;

        this.coFundedPercentageDiscSubject.next(firstResult.cofunded_percent);

        // eslint-disable-next-line no-restricted-globals
        const sUrl = location.search;
        if (sUrl.indexOf('uuid') > -1) {
          const getIncomingUrl_UUID = sUrl.replace('?uuid=', '');
          if (getIncomingUrl_UUID === firstResult.uuid) {
            const today = new Date();
            const endDate = new Date(firstResult.end_date * 1000);
            if (endDate < today) {
              this.isBannerExpiredSubject.next(true);
            }
          } else {
            this.isBannerExpiredSubject.next(true);
          }
        }
      } else {
        this.coFundedPromoCountSubject.next(0);
      }
    });
    this.subjectRefreshCoFundedPromos$.next();
  }

  updateCoFundedPromos() {
    this.subjectRefreshCoFundedPromos$.next();
  }

  get coFundedRestaurantData() {
    return this.coFundedRestaurantList;
  }

  get coFundedCrudPageParams() {
    return this.coFundedCrudParams;
  }

  updateCoFundedPromoCountSubject(length: number) {
    this.coFundedPromoCountSubject.next(length);
  }

  getUnredeemedCoFundedPromos() {
    const pageQuery = '?limit=20&offset=0';
    return this.http.get<IUnredeemedCoFunded>(`api://campaign-templates/unredeemed-cofunded${pageQuery}`);
  }

  getCampaigns(
    limit: number = 10,
    offset: number = 0,
    search: string = '',
    status: CofundedCampaignStatus | null = null,
    campaignType: PromoType | null = null,
    sortOrder?: 'newestFirst' | 'oldestFirst',
  ): Observable<{
    list: ICofundedPromoCampaign[],
    limit: number,
    offset: number,
    total: number
  }> {
    const pageQuery = `?limit=${limit}&offset=${offset}`;

    const searchQuery = search ? `&search=${search}` : '';
    const statusFilter = status ? `&status=${status}` : '';

    const typeFilter = campaignType ? `&campaign_type=${campaignType}` : '';

    const sort = sortOrder ? `&order_by=${sortOrder === 'newestFirst' ? 'desc' : 'asc'}` : '';

    return this.http.get<{
        campaign_template_list: ICofundedPromoCampaign[],
        meta?: {
          total_campaign_templates: number
        },
    }>(`api://campaign-templates${pageQuery}${searchQuery}${statusFilter}${typeFilter}${sort}`).pipe(
      map((result) => ({
        list: result.campaign_template_list,
        limit,
        offset,
        total: result.meta?.total_campaign_templates ?? 0,
      })),
    );
  }

  getCampaign(uuid: string): Observable<ICofundedPromoCampaign> {
    return this.http.get<ICofundedPromoCampaign>(`api://campaign-templates/${uuid}`);
  }

  createCampaign(details: Partial<ICofundedPromoCampaign>) {
    return this.http.post('api://campaign-templates/new', details);
  }

  updateCampaign_Admin(uuid: string, updates: Partial<ICofundedPromoCampaign>) {
    return this.http.patch(`api://campaign-templates/${uuid}`, updates);
  }

  appendRestaurants(uuid: string, ids: string[]) {
    return this.http.post(`api://campaign-templates/${uuid}/restaurant`, {
      restaurant_ids: ids,
    });
  }
}
