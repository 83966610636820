<div *ngIf="state$ | async as state; else loading">
  <div *ngIf="state.failureMsg">{{state.failureMsg}}</div>

  <div *ngIf="state.profile">
    <p>ID: <code>{{state.profile.id}}</code></p>
    <p>First name: <strong>{{state.profile.first_name}}</strong></p>
    <p>Last name: <strong>{{state.profile.last_name}}</strong></p>
    <p>Email: <strong>{{state.profile.email}}</strong></p>
    <p>Phone Number: <strong>{{state.profile.phone_number}}</strong></p>
    <p>Role: <strong>{{state.profile.role_name | role}}</strong></p>
    <p>
      Permissions:
      <span *ngFor="let p of state.profile.permissions"><code>{{p}}</code></span>
    </p>
    <p>
      Restaurants:
      <strong *ngFor="let r of state.profile.restaurants">{{r}} </strong>
    </p>
    <p>
      Restaurant sets:
      <strong *ngFor="let s of state.profile.restaurant_sets">{{s}} </strong>
    </p>
  </div>
</div>


<ng-template #loading>
  Loading...
</ng-template>
