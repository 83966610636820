import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import {
  tap, map, filter, switchMap, delay,
} from 'rxjs/operators';
import { UserService } from '../api/user.service';
import { AuthService, AuthState } from '../auth.service';

@Injectable({
  providedIn: 'root',
  })
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: object,
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isPlatformServer(this.platform)) { return true; }

    const { url } = state;

    return this.auth.authState$.pipe(
      filter((filterState) => filterState !== AuthState.Pending),
      map((mapState) => mapState === AuthState.Access),
      map((loggedIn) => loggedIn || this.router.parseUrl(`?target=${encodeURIComponent(url)}`)),
    );
  }
}
