import { Pipe, PipeTransform } from '@angular/core';

// eslint-disable-next-line no-bitwise
export const str2hue = (str) => str.split('').reduce((hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0) % 360;
export const hue2col = (sHue) => `hsl(${str2hue(sHue)},70%,60%)`;
@Pipe({
  name: 'emailColor',
  })
export class EmailColorPipe implements PipeTransform {
  transform(profileEmail: any): string {
    if (!profileEmail) { return '#cccccc'; }
    const str = Object.prototype.hasOwnProperty.call(profileEmail, 'email') ? profileEmail.email : profileEmail;
    return (str && typeof (str) === 'string') ? hue2col(str) : '#cccccc';
  }
}
