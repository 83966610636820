import { Injectable, ErrorHandler, Injector } from '@angular/core';
import {
  init, captureException, captureMessage, setUser,
} from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { HOST_ENV, BUILD_COMMIT } from 'src/ver';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeoutError } from 'rxjs';
import { IProfile } from './api/user.service';
import { PopupService } from './popup.service';

class CustomError extends Error {
  constructor(name = 'custom error', message = '', public meta?: Record<string, any>) {
    super(message);
    this.name = name;
  }
}

@Injectable({
  providedIn: 'root',
  })
export class SentryErrorHandlerService implements ErrorHandler {
  constructor(
    // cyclic dependency because the error handler is part of the app's initialisation phase
    // private pop: PopupService
    private injector: Injector,
  ) {
    if (environment.production) {
      init({
        dsn: 'https://952cafd44c31423eb2909bb6facac4b4@o307890.ingest.sentry.io/5194668',
        release: BUILD_COMMIT,
        environment: HOST_ENV,
        // @ts-ignore - injected value using token replace at build time
        sampleRate: HOST_ENV === 'PROD' ? 0.1 : 1.0, // 0.0 = no errors, 1.0 = all errors
      });
    }
  }

  handleError(error: any) {
    // console.error('############ CAUGHT BY GLOBAL ERROR HANDLER ############', error);

    // ##################
    // throw if not on hosting env [QA, PP, PROD]
    // ##################
    if (!environment.production) throw error;

    let eventId: string;
    const pop = this.injector.get(PopupService);

    // ##################
    // rxjs timeout error
    // ##################
    if (error instanceof TimeoutError) {
      pop
        .confirm('Some data is taking a while to arrive. Wait a while and then reload the page.', 'Note', 'Reload')
        .subscribe((res) => { if (res) { window.location.reload(); } });
      return;
    }

    // ##################
    // lazy load module error
    // ##################
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      pop
        .alert('A new version of the app is ready. The page will reload.', 'Note')
        .subscribe(() => { window.location.reload(); });
      return;
    }

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    // ##################
    // http error
    // ##################
    if (error instanceof HttpErrorResponse) {
      if (error.status === 0 && error.statusText === 'Unknown Error') return;

      const name = error.error.error?.developer_message || error.message || 'http error';
      const message = error.message || 'some http error occurred';
      console.warn(message);
      eventId = captureException(new CustomError(name, message, error));
      return;
    }

    // ##################
    // generic runtime error
    // ##################
    if (error instanceof Error) eventId = captureException(error);

    // ##################
    // general unexpected errors
    // ##################
    const name = error.message || 'unexpected error';
    const message = error.message || 'some unexpected error occurred';
    console.warn(message);
    if (error.error && typeof error.error === 'object') eventId = captureException(new CustomError(name, message, error.error));
    if (error.error && typeof error.error === 'string') eventId = captureMessage(error.error, 'error');

    pop
      .alert(`An unexpected error occurred. Our support staff is working on the problem. Reference: ${eventId}`, 'Oh crab!')
      .subscribe();
  }

  setUser(user: IProfile = null) {
    setUser(user);
  }
}
