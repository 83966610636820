<div class="modal-wrapper">
	<div class="modal-header">
	  <h3>User roles and permissions</h3>
	</div>
	<div class="modal-body">
    
	    <div *ngFor="let r of roles" class="role-item">
	      <h6>{{r.name | role}}</h6>
	      <ul>
	      	<li *ngFor="let p of r.permissions" class="role-perm">{{p}}</li>
	      </ul>
	      
	    </div>

	</div>
</div>
