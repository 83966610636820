/**
 * Taken from https://malcoded.com/posts/angular-dynamic-components/
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogComponent } from './dialog.component';
import { InsertionDirective } from './insertion.directive';
import { DialogService } from './dialog.service';
// import { DialogConfig } from './dialog-config';

@NgModule({
  imports: [CommonModule],
  declarations: [DialogComponent, InsertionDirective],
  })
export class DialogModule {}
