<div class="spacer"></div>

<div class="bottom-nav">
	<div class="mobile-menu flex">
		<ng-container *ngFor="let opt of options$ | async | slice:0:4">
			<a *ngIf="opt.routerLink" [routerLink]="opt.routerLink" routerLinkActive="active" [antic]="opt.faEvent" [id]="'lnkBottom'+opt.slug">
				<span *ngIf="opt.att" class="mobile-nav-item-badge">{{opt.att}}</span>
				<span [class]="opt.mdiClass"></span>
				<!-- <span>{{opt.name}} {{opt.badgeCount? opt.badgeCount : ''}}</span> 
				<span>{{opt.name}}</span>-->
				<span>{{isGroupRole && opt.slug.includes('indiedashboard') ? 'Restaurants' : opt.name}}</span>
				<span *ngIf="opt.badgeCount" class="countBadge" userColor="backgroundColor">{{opt.badgeCount? opt.badgeCount : ''}}</span>
			</a>
			<button *ngIf="opt.event" (click)="onClick(opt)" [id]="'btnBottom'+opt.slug">
				<span [class]="opt.mdiClass"></span>
				<!--<span>{{opt.name}}</span>-->
				<span>{{isGroupRole && opt.slug.includes('indiedashboard') ? 'Restaurants' : opt.name}}</span>
			</button>
		</ng-container>
		<!--<a id="bell" (click)="showContentCards()">
			<span class="countBadge" userBgColor *ngIf="unviewedCardCount">{{unviewedCardCount}}</span>
			<span class="mdi mdi-bell" userColor *ngIf="unviewedCardCount"></span>
			<span userColor *ngIf="unviewedCardCount">Alerts</span>
			<span class="mdi mdi-bell" *ngIf="!unviewedCardCount"></span>
			<span *ngIf="!unviewedCardCount">Alerts</span>
		</a>-->
		<a (click)="onClickMenu()" long-press (onLongPress)="showVersion()" class="nav-burger" id="btnMenu">
			<span class="mdi mdi-menu"></span>
			<span>Menu</span>
		</a>
	</div>

	<div *contextMenu="'bottomMenu'" class="mobile-menu-overlay">
		<div class="mobile-menu-container" [@slideInOut]>
			<div class="menu-header">
				<div class="site-logo">
					<img src="assets/logo-square.svg" alt="mrd-rssp">
				</div>
				<!-- <div class="close">
					<div class="mdi mdi-window-close"></div>
				</div> -->
			</div>
			<div class="menu-body">
				<ng-container *ngFor="let opt of options$ | async">
					<a *ngIf="opt.routerLink" [routerLink]="opt.routerLink" routerLinkActive="active" [antic]="opt.faEvent" [id]="'lnkDrawer'+opt.slug">
						<span [class]="opt.mdiClass"></span>
						<!-- <span>{{opt.name}} {{opt.badgeCount? opt.badgeCount : ''}}</span> 
						{{opt.name}}-->
						<span>{{isGroupRole && opt.slug.includes('indiedashboard') ? 'Restaurants' : opt.name}}</span>
						<span *ngIf="opt.badgeCount" class="countBadge" userColor="backgroundColor">{{opt.badgeCount? opt.badgeCount : ''}}</span>
						<span *ngIf="opt.att" class="mobile-expanded-menu-item-badge">{{opt.att}}</span>
					</a>
					<button *ngIf="opt.event" (click)="onClick(opt)" [id]="'btnDrawer'+opt.slug">
						<span [class]="opt.mdiClass"></span>
						<!--<span>{{opt.name}}</span>-->
						<span>{{isGroupRole && opt.slug.includes('indiedashboard') ? 'Restaurants' : opt.name}}</span>
					</button>
				</ng-container>
				<!-- <a id="bell" (click)="showContentCards()">
					<span class="countBadge" userBgColor *ngIf="unviewedCardCount">{{unviewedCardCount}}</span>
					<span class="mdi mdi-bell" userColor *ngIf="unviewedCardCount"></span>
					<span userColor *ngIf="unviewedCardCount">Alerts</span>
					<span class="mdi mdi-bell" *ngIf="!unviewedCardCount"></span>
					<span *ngIf="!unviewedCardCount">Alerts</span>
				</a>				 -->
			</div>


			<div class="menu-footer">
				<div class="user-profile flex" *ngIf="(profileState$ | async) as p">
					<div class="profile-user">
						<div class="name">{{p.profile?.first_name}} {{p.profile?.last_name}}</div>
						<div class="role">{{p.profile?.role_name | role}}</div>
					</div>
				</div>
				<button class="link" (click)="logout()"><span class="mdi mdi-logout-variant" id="btnSignOut"></span> Sign Out</button>
			</div>
		</div>
	</div>
</div>
