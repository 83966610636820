
<div class="p-2">
  <h5>Client</h5>
  <p>CodeBuild: <strong>{{build}}</strong></p>
  <p>GitHub: <strong>{{source}}</strong> (<a [href]="commitUrl" target="_blank">{{commit}}</a>)</p>
  <p>Build time: <strong>{{time}}</strong></p>
</div>

<div class="p-2">
  <h5>Environment</h5>
  <div *ngFor="let v of vars" class="px-3">
    {{v.name}} <code>{{v.value}}</code>
    <button *ngIf="v.editable" class="btn btn-sm btn-link mdi mdi-pencil" (click)="edit(v)"></button>
  </div>
  <div class="pt-2">
    <button class="btn btn-outline-primary mdi mdi-eraser ml-3" *ngIf="environmentResetable" (click)="revert()">Revert to defaults</button>
    <button class="btn btn-outline-primary mdi mdi-refresh ml-3" *ngIf="refreshRequired" (click)="refresh()">Refresh for changes to take effect</button>
  </div>
</div>
