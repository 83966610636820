import {
  Component, OnInit, OnDestroy, Inject, APP_INITIALIZER,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  map, tap, switchMap,
} from 'rxjs/operators';
import {
  UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn,
} from '@angular/forms';
import { UserService } from 'src/app/services/api/user.service';
import { setReactiveFormFieldErrors, getErMsgX } from 'src/app/entities/util';
import { HttpErrorResponse } from '@angular/common/http';
import { IErrorResponse } from 'src/app/entities/global';
import { Subscription, of } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

import { PopupService } from 'src/app/services/popup.service';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';

const mustMatch = (controlName: string,
  // eslint-disable-next-line consistent-return
  matchingControlName: string): ValidatorFn => (formGroup: UntypedFormGroup) => {
  const control = formGroup.controls[controlName];
  const matchingControl = formGroup.controls[matchingControlName];

  if (matchingControl.errors && !matchingControl.errors.mustMatch) {
    // return if another validator has already found an error on the matchingControl
    return null;
  }

  // set error on matchingControl if validation fails
  if (control.value !== matchingControl.value) {
    matchingControl.setErrors({ mustMatch: true });
  } else {
    matchingControl.setErrors(null);
  }
};

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss'],
  })
export class ChangePassComponent implements OnInit, OnDestroy {
  isInvalidId = false;
  #id: string;

  /**
   * `welcome` is `true` when presenting the welcome (set password) screen
   * and false to present a normal reset password screen.
   */
  welcome = false;

  showGuides = false;
  passwordHasUpper = false;
  passwordHasSpecial = false;
  passwordHasNumber = false;

  form = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    passwordConfirm: new UntypedFormControl('', [Validators.required]),
  }, {
    validators: mustMatch('password', 'passwordConfirm'),
  });

  somethingWentWrong = false;
  submitted = false;
  serverErrorMsg: string;
  serverAccepted = false;
	userId: string;

  private subRouteParams: Subscription;
  // private subRouteData:Subscription;
  private subFormValues: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private user: UserService,
    private note: NotificationService,
    private pop: PopupService,
    private ngFA: FirebaseAnalyticsService,
  ) {

  }

  ngOnInit(): void {
    this.subRouteParams = this.route.queryParamMap.pipe(
      map((params) => ({
        id: params.get('id'),
        code: params.get('code'),
				user_id: params.get('user_id'),
      })),
      tap((p) => {
				if (p.user_id) this.userId = p.user_id;
        // if `code` exists the user has not confirmed his/her email address
        this.welcome = !!p.code;

        if (p.id) {
        	this.ngFA.logEvent('view_reset_password_screen');
        } else {
          this.ngFA.logEvent('view_setup_password_screen', { invite_id: p.code }); // invites are accompanied by codes
        }
      }),
      switchMap((p) => {
        if (p.id) { return of({ change_password_id: p.id }); }

        // the email confirmation code needs to be swapped for a password reset `id`
        return this.user.getChangePasswordId(p.code);
      }),
      map((res) => res.change_password_id),
      tap((id) => this.#id = id),
    ).subscribe((id) => {
      this.isInvalidId = id == null;
    }, (res) => {
      this.feedbackErrors(res);
    });

    this.subFormValues = this.form.controls.password.valueChanges.subscribe((value) => {
      this.passwordHasUpper = /[A-Z]+/.test(value);
      this.passwordHasNumber = /[0-9]+/.test(value);
      // eslint-disable-next-line no-useless-escape
      this.passwordHasSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/.test(value);
    });
  }

  showTC() {
    this.pop.showTsAndCs();
  }

  onFocusPassword() {
    this.showGuides = true;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.serverErrorMsg = null;
    of(this.form.value).pipe(
      tap((values) => this.ngFA.logEvent(this.welcome ? 'tap_setup_password' : 'tap_reset_password')),
      switchMap((values) => this.user.setPassword(values.password, this.#id)),
    )
      .subscribe((res) => {
        this.serverAccepted = true;
        this.note.toastSuccess('Your password has been updated.');
        this.ngFA.logEvent(this.welcome ? 'setup_password_completed' : 'reset_password_completed');

        // after a successful password (re)set return to login
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1000);
      }, (res) => {
        this.feedbackErrors(res);
      });
  }

  private feedbackErrors(res: HttpErrorResponse) {
    setReactiveFormFieldErrors(this.form, res);
    const eTag_setup = 'tap_setup_password_errors';
    const eTag_reset = 'tap_reset_password_errors';
    const eData = this.welcome ? eTag_setup : eTag_reset;
    this.ngFA.logEvent(eData, { code: res?.status ?? 0, message: getErMsgX(res) });
    const body = res.error as IErrorResponse;
    if (body?.error?.error_map && 'id' in body.error.error_map) {
      this.isInvalidId = true;
      this.serverErrorMsg = body.error.error_map.id.join(' ');
    } else if (body?.error?.error_map && 'code' in body.error.error_map) {
      this.isInvalidId = true;
      this.serverErrorMsg = body.error.error_map.code.join(' ');
    } else {
      this.serverErrorMsg = body?.error?.friendly_message ?? 'Failed to reset your password.';
      this.somethingWentWrong = true;
      setTimeout(() => {
        this.somethingWentWrong = false;
      }, 10000);
    }
  }

	resendInvite() {
		this.user.resendInvitationLink(this.userId).subscribe(res => {
			if(res) this.note.toastSuccess('Invite successfully resent to your email');
		}, err => this.note.toastSuccess('Invitation link could not be sent due to a technical error'))
	}

  ngOnDestroy() {
    this.subRouteParams.unsubscribe();
    // this.subRouteData.unsubscribe();
    this.subFormValues.unsubscribe();
  }
}
