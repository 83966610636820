import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BUILD_TIME, BUILD_SOURCE } from './ver';

if (environment.production) {
  enableProdMode();

  const t = +BUILD_TIME;
  // @ts-ignore
  if (BUILD_SOURCE !== '[src]' && !Number.isNaN(t)) {
    //console.info(`${BUILD_SOURCE} (${new Date(t).toLocaleString()})`);
  } else {
    //console.warn('Missing build information!');
  }

  environment.faDebug = !!localStorage.getItem('fa-debug');
} else {
  environment.faDebug = true;
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
