import { Observable, Subject } from 'rxjs';

export class DialogRef<T = any> {
  constructor() {}

  dismissible = false;
  className = '';
  title = null;

  private readonly _afterClosed = new Subject<T>();
  afterClosed: Observable<T> = this._afterClosed.asObservable();

  close(result?: T) {
    this._afterClosed.next(result);
    this._afterClosed.complete();
  }
}
