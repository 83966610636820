import { Injectable, Injector } from '@angular/core';
import {
  Analytics, logEvent, isSupported, getAnalytics,
} from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
  })
export class FirebaseAnalyticsService {
	private _analytics: Analytics;
	constructor(private injector: Injector) {
	  isSupported().then((r) => {
	    if (r) {
	      this._analytics = this.injector.get(Analytics);
	    }
	  });
	}

	get analytics() {
	  return this._analytics;
	}
	logEvent(tag, paramsObj?) {
	  isSupported().then((r) => {
	    if (r) {
	      logEvent(getAnalytics(), tag, paramsObj);
	    }
	  });
	}
}
