import {
  Directive, ElementRef, HostListener, Input,
} from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Directive({
  selector: '[antic]',
  })
export class UserInteractionMetricDirective {
  @Input() antic: string;

  @HostListener('click') onClick() {
    if (this.antic) { logEvent(this.analytics, this.antic); }
    // console.log("[antic]",this.antic);
  }

  constructor(
    private el: ElementRef,
    private analytics: Analytics,
  ) {

  }
}
