import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBCap } from 'src/app/entities/budget_cap';
import { DialogService } from '../modules/dialog/dialog.service';
import { AlertComponent, IDialogConfigData as IAlertParams } from '../popups/alert/alert.component';
import { RoleInfoComponent, IDialogConfigData as IRoleInfoParams, IRole } from '../popups/role-info/role-info.component';
import { userRoles } from '../entities/user-role';
import { TermsAndConditionsComponent } from '../popups/terms-and-conditions/terms-and-conditions.component';

@Injectable({
  providedIn: 'root',
  })
export class PopupService {
  constructor(
    private dialog: DialogService,
  // eslint-disable-next-line no-empty-function
  ) {}

  alert(message: string | string[], title = '', superTitle?: string, budgetCapData?: IBCap): Observable<boolean> {
    const inputParams: IAlertParams = {
      message, title, superTitle, budgetCapData,
    };
    return this.dialog.open(AlertComponent, { data: inputParams, dismissible: false }).afterClosed;
  }

  showAllRestaurants(restaurants: any, title = '', isRestaurants: boolean = true): Observable<boolean> {
    const inputParams: any = {
      restaurants, title, isRestaurants,
    };
    return this.dialog.open(AlertComponent, { data: inputParams, dismissible: false }).afterClosed;
  }

  confirm(message: string, title = '', okayText: string = null): Observable<boolean> {
    const inputParams: IAlertParams = { message, title, okayText };
    return this.dialog.open(AlertComponent, { data: inputParams, dismissible: true }).afterClosed;
  }

  roleInfo(...roles: IRole[]) {
    roles.map((r) => {
      const role = userRoles.find((ur) => ur.id === r.name);
      if (role) { r.name = role.name; }
      return r;
    });
    const inputParams: IRoleInfoParams = { roles };
    return this.dialog.open(
      RoleInfoComponent, { data: inputParams, dismissible: true },
    ).afterClosed;
  }

  showTsAndCs() {
    return this.dialog.open(TermsAndConditionsComponent, { dismissible: true, className: 'terms-dialog' }).afterClosed;
  }
}
