import { Pipe, PipeTransform } from '@angular/core';

/**
 * Password strength tester.
 * @param pass The password
 * @returns
 */
const scorePassword = (pass: string) => {
  let score = 0;
  if (!pass) { return score; }

  // award every unique letter until 5 repetitions
  const letters = {};
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let vcount = 0;
  Object.keys(variations).forEach((check) => {
    vcount += (variations[check] === true) ? 1 : 0;
  });
  score += (vcount - 1) * 10;

  return score;
};
@Pipe({
  name: 'passwordStrength',
  })
export class PasswordStrengthPipe implements PipeTransform {
  transform(password: string, ...args: unknown[]): unknown {
    const score = scorePassword(password);
    if (score > 70) { return 'strong'; }
    if (score > 45) { return 'good'; }
    if (score >= 20) { return 'weak'; }
    return '';
  }
}
