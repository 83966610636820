import { Component, Input, OnInit } from '@angular/core';
import { DialogConfig } from 'src/app/modules/dialog/dialog-config';
import { DialogRef } from 'src/app/modules/dialog/dialog-ref';

export interface IRole {
  name: string;
  permissions: string[];
}
export interface IDialogConfigData {
  roles: IRole[];
}

@Component({
  selector: 'app-role-info',
  templateUrl: './role-info.component.html',
  styleUrls: ['./role-info.component.scss'],
  })
export class RoleInfoComponent implements OnInit {
  // roles: IRole[];
  roles: any;

  constructor(
    // public config: DialogConfig<IDialogConfigData>,
    // public dialog: DialogRef<void>,
  ) {
    // this.roles = config.data.roles;
  }

  @Input() set config(val: DialogConfig<IDialogConfigData>) {
    this.roles = val;
  }

  ngOnInit(): void {
  }
}
