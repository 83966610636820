import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  shareReplay, switchMap, map, tap, catchError,
} from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StoreService } from '../store.service';

export interface IEnvValues {
  api: string;
}

const KEY = 'envOverrides';

@Injectable({
  providedIn: 'root',
  })
export class EnvService {
  private overrideValues = new BehaviorSubject<{[key: string]: string}|null>(null);
  value$: Observable<IEnvValues>;

  get hasOverrideValues() {
    return !!this.store.get(KEY);
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private http: HttpClient,
    private store: StoreService,
  ) {
    const obs: Observable<IEnvValues> = isPlatformBrowser(this.platform) ? http.get<IEnvValues>('/api/env') : of({ api: null });

    // environment variables are provided by the local Node.js server,
    // but may also be overridden by alternative values stored locally.
    this.value$ = obs.pipe(
      shareReplay(),
      map((values) => {
        const storedOverrides = this.store.get(KEY);
        const overrides = storedOverrides ? JSON.parse(storedOverrides) : null;
        return overrides ? { ...values, ...overrides } : values;
      }),
      catchError((res: HttpErrorResponse) => of({
        api: environment.ssrApiHost,
      })),
      shareReplay(1),
    );
  }

  override(values: {[key: string]: string}|null) {
    const storedOverrides = this.store.get(KEY);
    // let overrides:{[key:string]:string};
    if (storedOverrides) { values = { ...JSON.parse(storedOverrides), ...values }; }
    this.store.set(KEY, JSON.stringify(values));
  }

  reset() {
    this.store.remove(KEY);
  }

  getAPIVersion() {
    return this.http.get<{version: string, info: string}>('api://meta/version');
  }
}
