import {
  Component, OnInit, HostListener, HostBinding, Input, Inject,
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import * as Env from 'src/ver';

import { NavService, IOption } from 'src/app/services/nav.service';
import { Router } from '@angular/router';
import { ContextMenuService } from 'src/app/modules/context-menu/contextMenu.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService, IProfileState } from 'src/app/services/api/user.service';
import { Observable } from 'rxjs';
import { PopupService } from 'src/app/services/popup.service';
import { UserRole } from 'src/app/entities/user-role';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';

@Component({
  selector: '[sidebar]',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0,
      })),
    transition('void <=> *', animate('0.2s')),
    ]),
// trigger('openClose', [
//   transition('open => closed', [
//     animate('2s')
//   ]),
//   transition('closed => open', [
//     animate('2s')
//   ]),
// ]),
  ],
  })
export class SideNavComponent implements OnInit {
  @Input() hostPageName: string;
  options$: Observable<IOption[]>;
  profile_ui_showActions = false;

  currAction = '';

  profileState$: Observable<IProfileState>;

  displayMenuBanner = false;

	isGroup: boolean = false;
	//isGroupRole_Franchise: boolean = false;

  constructor(
    private nav: NavService,
    private auth: AuthService,
    private router: Router,
    private me: UserService,
    private contextMenuService: ContextMenuService,
    private pop: PopupService,
    private ngFA: FirebaseAnalyticsService,
  ) {
    this.profileState$ = this.me.profile;
  }

  ngOnInit(): void {
		this.options$ = this.nav.options$;
    this.me.profile.subscribe((profileState) => {
      const role = profileState.profile?.role_name ?? null;
			this.isGroup = role === UserRole.GroupHead || role === UserRole.FranchiseHead;
			//this.isGroupRole_Franchise= role === UserRole.FranchiseHead;
      this.displayMenuBanner = (role === UserRole.RestaurantManager
        || role === UserRole.RestaurantOwner);
    });
  }

  showVersion() {
    const msg:string[] = [`Source: ${Env.BUILD_SOURCE} [${Env.BUILD_COMMIT}]`];
    const t = +Env.BUILD_TIME;
    if (!Number.isNaN(t)) msg.push(`Build time: ${new Date(t).toLocaleString()}`);
    this.pop.alert(msg, 'Version');
  }

  ShowAction(divVal: string) {
    this.contextMenuService.openContextMenu('profile-menu');
  }

  navigateTo(commands: string[]) {
    this.router.navigate(commands);
  }

  onOptionClick(opt: IOption) {
    switch (opt.event) {
      case 'logout':
        this.logout();
        break;

      default:
        break;
    }
  }

  showTC() {
    this.pop.showTsAndCs();
  }

  logout() {
    this.auth.clear();
    this.router.navigate(['/']);
  }

  logEvent_CreatePromo() {
    this.ngFA.logEvent('tap_create_promos', { source_page: this.hostPageName });
  }
}
