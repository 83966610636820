<div class="time-period">

	<div *ngIf="_selected" class="action">
		<div class="select">
			<div class="button" tabindex="0">
				<span tabindex="0">{{_selected | timePeriod}}</span>
				<span class="mdi mdi-chevron-down"></span>
			</div>
			<div [formGroup]="form" class="options">
				<label *ngFor="let item of selection" [class.active]="_selected.name === item.name">
					<input type="radio" formControlName="date" [value]="item.id" [id]="item.id" (click)="tap(item)">
					{{item.name}}
				</label>
			</div>
		</div>
	</div>

</div>


<!-- <div class="time-period flex">
	<div class="col col-left">
		<h3 *ngIf="_selected">{{_selected | timePeriod}}</h3>
	</div>
	<div class="col col-right">

		<div *ngIf="_selected" class="action textRight">
			<div class="select">
				<div class="button" tabindex="0">
					<span tabindex="0">{{_selected.name}}</span>
					<span class="mdi mdi-chevron-down"></span>
				</div>
				<div [formGroup]="form" class="options">
					<label *ngFor="let item of selection" [class.active]="_selected.name === item.name">
						<input type="radio" formControlName="date" [value]="item.id" [id]="item.id" (click)="tap(item)">
						{{item.name}}
					</label>
				</div>
			</div>
		</div>

	</div>
</div> -->