import { Pipe, PipeTransform } from '@angular/core';
import { ITimePeriod } from '../components/period-selector/period-selector.component';

const MM = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

@Pipe({
  name: 'timePeriod',
  })
export class TimePeriodPipe implements PipeTransform {
  transform(timePeriod: ITimePeriod): unknown { // Jul 22, 2020 - Jul 28, 2020
    if (!timePeriod || !timePeriod.start || !timePeriod.end) { return null; }
    const { start, end } = timePeriod;
    return `${MM[start.getMonth()]} ${start.getDate()}, ${start.getFullYear()} - ${MM[end.getMonth()]} ${end.getDate()}, ${end.getFullYear()}`;
  }
}
