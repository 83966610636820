import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPassComponent } from './components/forgot-pass/forgot-pass.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EnvComponent } from './components/env/env.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NonAuthGuard } from './services/guards/non-auth.guard';
import { AuthGuard } from './services/guards/auth.guard';
import { PermissionGuard } from './services/guards/permission.guard';
import { Permission } from './entities/user-permission';
import { ChangePassComponent } from './components/change-pass/change-pass.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { title: '' },
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
    data: { title: 'Login' },
  }, {
    path: 'forgot-password',
    component: ForgotPassComponent,
    canActivate: [NonAuthGuard],
    data: { title: 'Reset Password' },
  }, {
    path: 'change-password',
    component: ChangePassComponent,
    canActivate: [NonAuthGuard],
    data: { title: 'Change Password' },
  }, {
    path: 'accept-invite',
    component: ChangePassComponent,
    canActivate: [NonAuthGuard],
    data: { title: 'Setup Password', variation: 'welcome' },
  }, {
    path: 'me',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { title: 'Profile', permissionsReq: [Permission.UserEdit] },
  }, {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
  }, {
    path: 'promos',
    loadChildren: () => import('./modules/promos/promos.module').then((m) => m.PromosModule),
    data: {
      tabs: [
        { route: ['/promos', 'current'], title: 'Performance' },
        { route: ['/promos', 'all'], title: 'All campaigns' },
      ],
      createBtn: 'promo',
    },
  }, {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  }, {
    path: 'analytics',
    loadChildren: () => import('./modules/analysis/analysis.module').then((m) => m.AnalysisModule),
  }, {
    path: 'audit',
    loadChildren: () => import('./modules/audit/audit.module').then((m) => m.AuditModule),
  }, {
    path: 'system',
    loadChildren: () => import('./modules/system/system.module').then((m) => m.SystemModule),
  }, {
    path: 'experimental',
    loadChildren: () => import('./modules/exp/exp.module').then((m) => m.ExpModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: { title: 'Experimental', permissionsReq: [Permission.Super] },
  }, {
    path: 'style-guide',
    redirectTo: 'styleguide',
  }, {
    path: 'styleguide',
    data: { title: 'Styleguide' },
    loadChildren: () => import('./modules/style-guide/style-guide.module').then((m) => m.StyleGuideModule),
  }, {
    path: 'env',
    component: EnvComponent,
  }, {
    path: 'orders',
    loadChildren: () => import('./modules/order-history/order-history.module').then((m) => m.OrdersModule),
  }, {
    path: 'payments',
    loadChildren: () => import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      tabs: [
        { route: ['/payments', 'earnings'], title: 'Earnings' },
        { route: ['/payments', 'invoices'], title: 'Invoices' },
      ],
      permissionsReq: [Permission.FinanceView],
    },
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      title: 'Reports',
      permissionsReq: [Permission.FinanceView, Permission.GroupAnalyticsView],
      hideCreatePromoBtn: true,
    },
  },
  {
    path: 'cofunded-promos',
    loadChildren: () => import('./modules/cofunded-promos/cofunded-promos.module').then((m) => m.CofundedPromosModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      title: 'Co-funded promos',
      permissionsReq: [Permission.AdminView],
    },
  },
  {
    path: 'grocery-campaigns',
    loadChildren: () => import('./modules/grocery-campaigns/grocery-campaigns.module').then((m) => m.GroceryCampaignsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      title: 'Grocery Campaigns',
      permissionsReq: [Permission.GroceryCampaignView],
    },
  }, {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule),
  }, {
    path: 'advertising',
    loadChildren: () => import('./modules/adverts/adverts.module').then((m) => m.AdvertsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      title: 'Advertising',
      showRestaurantSelect: true,
      hideCreatePromoBtn: true,
    },
  }, {
    path: 'group-dashboard',
    loadChildren: () => import('./modules/group-dashboard/group-dashboard.module').then((m) => m.GroupDashboardModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      title: 'Group Dashboard',
      showRestaurantSelect: true,
      hideCreatePromoBtn: true,
    },
  }, {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
    })],
  exports: [RouterModule],
  })
export class AppRoutingModule { }
