export const environment = {
  production: true,
  ssrApiHost: 'https://rssp-api.qa.mrd.com',
  expApiHost: 'https://api.qa.mrd.com',
  googleClientId: '554668785649-aeabaa37vo8duhdn97r0jda3tvc5kohb.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyA8kAQTJCh14USnJ-TIAUBbPabqIAQR2WI',
    authDomain: 'tdt-qa-restaurant-portal.firebaseapp.com',
    databaseURL: 'https://tdt-qa-restaurant-portal.firebaseio.com',
    projectId: 'tdt-qa-restaurant-portal',
    storageBucket: 'tdt-qa-restaurant-portal.appspot.com',
    messagingSenderId: '941385423303',
    appId: '1:941385423303:web:0c7d8b318902c0657361e2',
    measurementId: 'G-MRXG33DGPZ',
  },
  faDebug: true,
  braze: {
    enable: true,
    apiKey: 'd39529d0-5ce9-4f09-b1c1-2c2efc8046df',
    baseUrl: 'sdk.fra-01.braze.eu',
    debug: true,
  },
  showBanners: false,
  s3bucket: 'mrd-marketplace-images-449369602245-eu-west-1',
};
