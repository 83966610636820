import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCardsComponent } from './content-cards/content-cards.component';
import { InAppMessagesComponent } from './in-app-messages/in-app-messages.component';

@NgModule({
  declarations: [
  ContentCardsComponent,
  InAppMessagesComponent,
  ],
  imports: [
  CommonModule,
  ],
  exports: [
  ContentCardsComponent,
  InAppMessagesComponent,
  ],
  })
export class BrazeModule { }
