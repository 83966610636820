import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
  })
export class RestaurantSelectorService {
  private reqToShowSelectorSubject = new Subject<Function | void>();
  readonly showSelector$ = this.reqToShowSelectorSubject.asObservable();

  constructor() { }

  triggerOpenSelector(nextFn: Function) {
    this.reqToShowSelectorSubject.next(nextFn);
  }
}
