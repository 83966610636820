import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line no-shadow
export enum ToastType {
  Neutral = '',
  Success = 'success',
  Error = 'error',
}

export interface IToast {
  type: ToastType;
  msg: string;
}

@Injectable({
  providedIn: 'root',
  })
export class NotificationService {
  private toasts = [];
  private toastSubject = new BehaviorSubject<IToast[]>(this.toasts);
  toast$ = this.toastSubject.asObservable();

  private etag: string;
  private timerVersionTest: any;
  private newVersionNoticeSubject = new BehaviorSubject<boolean>(false);
  newVersionNotice$ = this.newVersionNoticeSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
    if (environment.production) {
      // immediately get the ETag hash of index.html
      this.http.head('/', { observe: 'response' }).subscribe((res) => {
        this.etag = res.headers.get('etag');
        // on interval test send the server the laoded ETag hash
        this.timerVersionTest = setInterval(() => this.testVersion(), 1000 * 60 * 5);
      }, (er) => { /* ignore */ });
    }
  }

  toast(msg: string, type = ToastType.Neutral, hideAfter = 6000) {
    const toast: IToast = { type, msg };
    this.toasts.push(toast);
    this.toastSubject.next(this.toasts);

    setTimeout(() => {
      this.toasts = this.toasts.filter((t) => t !== toast);
      this.toastSubject.next(this.toasts);
    }, hideAfter);
  }

  toastSuccess(msg: string, hideAfter = 6000) {
    this.toast(msg, ToastType.Success, hideAfter);
  }

  toastError(msg: string, hideAfter = 9000) {
    this.toast(msg, ToastType.Error, hideAfter);
  }

  testVersion() {
    this.http.request('HEAD', '/', {
      observe: 'response',
      headers: { 'if-none-match': this.etag, 'cache-control': 'none' },
    }).subscribe((res) => {
      // status 304 means the version is unchanged
      if (res.status === 200) {
        this.newVersionNoticeSubject.next(true);
        // the loaded app is forever outdated no point in continued testing
        clearInterval(this.timerVersionTest);
      }
    }, (er) => { /* ignore 304 error interpretation */ });
  }
}
