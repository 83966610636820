import {
  Component, OnInit, OnDestroy, Input, Inject,
} from '@angular/core';
import {
  Router, Event, NavigationStart, NavigationEnd, ActivatedRoute,
} from '@angular/router';
import {
  map, switchMap, tap, delay,
} from 'rxjs/operators';
import {
  of, combineLatest, Subscription,
} from 'rxjs';
import { UsersService } from 'src/app/modules/admin/users/users.service';
import { IUser } from 'src/app/entities/user';
import { RestaurantsService } from 'src/app/modules/admin/restaurants/restaurants.service';
import { IRestaurantSet } from 'src/app/entities/restaurant-set';
import { userRoles } from 'src/app/entities/user-role';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';

@Component({
  selector: '[navtabs]',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss'],
  })
export class NavTabsComponent implements OnInit, OnDestroy {
  isPromosActive: boolean = false;
  hidePromoBtn: boolean = false;
  _tabData: any = [];
  @Input() set tabData(data: any) {
    this._tabData = data;
  }
  @Input() set buttonType(type: 'promo') {
    this.isPromosActive = type === 'promo';
  }
  @Input() hostPageName: string;

  viewAsUserId: string;
  viewAsUserName: string;

  viewForRestaurantSetId: string;
  viewForRestaurantSetName: string;

  private subRouteEvents: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private users: UsersService,
    private restaurants: RestaurantsService,
    private ngFA: FirebaseAnalyticsService,
  ) {

  }

  ngOnInit(): void {
    if (window.location.href.indexOf('payments') > -1) {
      this.hidePromoBtn = true;
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('payments') > -1) {
          this.hidePromoBtn = true;
        } else {
          this.hidePromoBtn = false;
        }
      }
    });
    this.subRouteEvents = this.route.queryParamMap.pipe(
      delay(0),
      map((map) => ({
        userId: map.get('as'),
        restaurantSetId: map.get('for'),
      })),
      tap((p) => {
        this.viewAsUserId = p.userId;
        this.viewForRestaurantSetId = p.restaurantSetId;
      }),
      switchMap((p) => {
        const user$ = p.userId ? this.users.getUser(p.userId) : of(null as IUser);
        const restaurantSet$ = p.restaurantSetId
          ? this.restaurants.getRestaurantSet(p.restaurantSetId) : of(null as IRestaurantSet);
        return combineLatest([user$, restaurantSet$]);
      }),
      map(([user, restaurantSet]) => ({
        userName: user ? `${user.first_name} ${user.last_name} (${userRoles.find((r) => r.id === user.role)?.name ?? 'unknown role'})` : null,
        restaurantSetName: restaurantSet ? restaurantSet.name : null,
      })),
    ).subscribe((names) => {
      this.viewAsUserName = names.userName;
      this.viewForRestaurantSetName = names.restaurantSetName;
    });
  }

  logEvent_CreatePromo() {
  	this.ngFA.logEvent('tap_create_promos', { source_page: this.hostPageName });
  }

  ngOnDestroy() {
    this.subRouteEvents.unsubscribe();
  }
}
