import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgModule, ErrorHandler, OnInit, PLATFORM_ID,
} from '@angular/core';
import {
  MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MomentDateModule, MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';

// MODULES
import { TraceService } from '@sentry/angular';
import { FirebaseApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { DialogModule } from './modules/dialog/dialog.module';
import { ContextMenuModule } from './modules/context-menu/ngx-contextmenu';
import { BrazeModule } from './modules/braze/braze.module';

// SERVICES
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';
import { AuthHttpInterceptorService } from './services/http-interceptors/auth-http-interceptor.service';
import { ApiHttpInterceptorService } from './services/http-interceptors/api-http-interceptor.service';
import { DialogService } from './modules/dialog/dialog.service';
import { ContextMenuService } from './modules/context-menu/contextMenu.service';

// COMPONENTS
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ForgotPassComponent } from './components/forgot-pass/forgot-pass.component';
import { SideNavComponent } from './components/nav/side-nav/side-nav.component';
import { BottomNavComponent } from './components/nav/bottom-nav/bottom-nav.component';
import { EnvComponent } from './components/env/env.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AlertComponent } from './popups/alert/alert.component';
import { TermsAndConditionsComponent } from './popups/terms-and-conditions/terms-and-conditions.component';
import { ChangePassComponent } from './components/change-pass/change-pass.component';
// import { RoleInfoComponent } from './popups/role-info/role-info.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
  AppComponent,
  HomeComponent,
  LoginComponent,
  ProfileComponent,
  ForgotPassComponent,
  SideNavComponent,
  BottomNavComponent,
  EnvComponent,
  PageNotFoundComponent,
  AlertComponent,
  TermsAndConditionsComponent,
  ChangePassComponent,
// RoleInfoComponent,
  ],
  imports: [
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
  BrowserAnimationsModule,
  AppRoutingModule,
  HttpClientModule,
  FormsModule,
  DialogModule,
  ContextMenuModule.forRoot(),
  SharedModule,
  BrazeModule,
  MatNativeDateModule,
  MomentDateModule,
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideAnalytics(() => initializeAnalytics(initializeApp(environment.firebase))),
  ],
  providers: [
  ContextMenuService,
  DialogService,
// { provide: TraceService, deps:[Router] },
  { provide: ErrorHandler, useClass: SentryErrorHandlerService },
// auth first as the refresh relies on api
  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useExisting: ApiHttpInterceptorService, multi: true },
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
  })
export class AppModule {
}
