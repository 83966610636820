import {
  Component, OnInit, OnDestroy, Inject,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  tap, switchMap, take, map,
} from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IErrorResponse } from 'src/app/entities/global';
import { setReactiveFormFieldErrors, getErMsgX } from 'src/app/entities/util';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss'],
  })
export class ForgotPassComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
  });

  submitted = false;
  busy = false;
  serverErrorMsg: string;
  serverAccepted = false;
  targetPath: string;

  private subQueryParams: Subscription;

  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private ngFA: FirebaseAnalyticsService,
  ) { }

  ngOnInit(): void {
    this.subQueryParams = this.route.queryParamMap.pipe(
      map((map) => map.get('target')), // preserve auth-guard redirect
    ).subscribe((targetPath) => {
      this.targetPath = targetPath;
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.serverErrorMsg = null;
    of(this.form.value.email).pipe(
      tap((email) => this.busy = true),
      tap((email) => this.ngFA.logEvent('tap_send_reset_link', { email })),
      switchMap((email) => this.user.resetPassword(email)),
      tap((res) => this.busy = false, (res) => this.busy = false),
    )
      .subscribe((res) => {
        this.serverAccepted = true;
        this.ngFA.logEvent('tap_send_reset_link_completed');
      }, (res: HttpErrorResponse) => this.feedbackErrors(res));
  }

  private feedbackErrors(res: HttpErrorResponse) {
    setReactiveFormFieldErrors(this.form, res);
    const body = res.error as IErrorResponse;
    this.serverErrorMsg = body?.error?.friendly_message ?? 'Failed to reset your password.';
    this.form.controls.email.setErrors({ server: this.serverErrorMsg });
    this.ngFA.logEvent('tap_send_reset_link_errors', { code: res?.status ?? 0, message: getErMsgX(res) });
  }

  ngOnDestroy() {
    this.subQueryParams.unsubscribe();
  }
}
