<div class="page-login forgot-password">
	<div class="container">
		<div class="reset-step1" *ngIf="!serverAccepted">
			<a href="" class="site-logo" id="lnkLogoHome">
				<img src="assets/logo-square.svg" alt="mrd-rssp">
			</a>
			<h4>Reset password</h4>
			<p>We’ll send you an email with a link to choose a new password.</p>

			<div class="form-wrapper">
				<form [formGroup]="form" (ngSubmit)="onSubmit()">
		            <form-row focusClass="focused">
		                <input appInputRef type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" id="inputEmail" placeholder=" " autofocus>
		                <label for="inputEmail">Enter email address</label>
					</form-row>
					<div *ngIf="submitted && form.controls.email.errors" class="frm_error">
						<div *ngIf="form.controls.email.errors.required">Please enter your email</div>
						<div *ngIf="form.controls.email.errors.email">Invalid email</div>
						<div *ngIf="form.controls.email.errors.server">{{form.controls.email.errors.server}}</div>
					</div>
								

		            <div class="form-row submit buttons">
		                <button class="btn" type="submit" id="btnSendLink" [disabled]="busy">
		                	<div class="form-loader" role="status" *ngIf="busy">
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </div>
		                    Send reset link
		                </button>

		                <span class="form-link">
		                    <a routerLink="/" [queryParams]="{'target':targetPath}" id="lnkResultBack">Back to login</a>
		                </span>
		            </div>

				</form>
			</div>
		</div>

		<div class="reset-step2" *ngIf="serverAccepted">
			<h4>Email sent!</h4>
			<p>A reset email is on its way to {{this.form.value.email}} Didn’t receive it? Check your spam folder.</p>

			<div class="btn-group textCenter">
				<a class="btn" routerLink="/" [queryParams]="{'target':targetPath}" id="lnkBack">Back to login</a>
				<!-- <button class="btn primary" routerLink="/">Back to login</button> -->
			</div>

		</div>

	</div>
</div>