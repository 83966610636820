import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import * as Env from 'src/ver';
import { IOption, NavService } from 'src/app/services/nav.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContextMenuService } from 'src/app/modules/context-menu/contextMenu.service';
import { UserService, IProfileState } from 'src/app/services/api/user.service';
import { PopupService } from 'src/app/services/popup.service';
import { BrazeService } from '../../../modules/braze/braze.service';
import { UserRole } from 'src/app/entities/user-role';

@Component({
  selector: '[bottom-nav]',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
  animations: [
  trigger('slideInOut', [
    transition(':enter', [
      style({ transform: 'translate3d(100%, 0px, 0)' }),
      animate('200ms linear', style({ transform: 'translate3d(0%, 0px, 0)' })),
      ]),
    transition(':leave', [
      animate('200ms linear', style({ transform: 'translate3d(100%, 0px, 0)' })),
      ]),
    ]),
  ],
  })
export class BottomNavComponent implements OnInit {
  options$: Observable<IOption[]>;
  profileState$: Observable<IProfileState>;

  unviewedCardCount = 0;
	isGroupRole: boolean = false;

  private subUnviewedContentCards: Subscription;

  constructor(
    private nav: NavService,
    private router: Router,
    private auth: AuthService,
    private user: UserService,
    private contextMenuService: ContextMenuService,
    private braze: BrazeService,
    @Inject(DOCUMENT) private document: Document,
    private pop: PopupService,
  ) {
    this.profileState$ = this.user.profile;
  }

  ngOnInit(): void {
    this.options$ = this.nav.options$;
    this.user.profile.subscribe((profileState) => {
      const role = profileState.profile?.role_name ?? null;
			this.isGroupRole = role === UserRole.GroupHead;
    });
    this.subUnviewedContentCards = this.braze.getUnviewedContentCardCountObs()
      .subscribe((count) => {
        this.unviewedCardCount = count;
      });
  }

  // showContentCards() {
  //   this.braze.showContentCards();
  // }

  onClick(opt: IOption) {
    switch (opt.event) {
      case 'logout':
        this.logout();
        break;

      default:
        break;
    }
  }

  onClickMenu() {
    this.contextMenuService.openContextMenu('bottomMenu');
    this.document.body.parentElement.classList.add('ios');
  }

  logout() {
    this.auth.clear();
    this.router.navigate(['/']);
  }

  showVersion() {
    const msg:string[] = [`Source: ${Env.BUILD_SOURCE} [${Env.BUILD_COMMIT}]`];
    const t = +Env.BUILD_TIME;
    if (!Number.isNaN(t)) msg.push(`Build time: ${new Date(t).toLocaleString()}`);
    this.pop.alert(msg, 'Version');
  }

  ngOnDestroy() {
    if (this.subUnviewedContentCards) {
      this.subUnviewedContentCards.unsubscribe();
      this.subUnviewedContentCards = null;
    }
  }
}
