// Taken from: https://gist.github.com/metanav/44341d6be84724d3b2c2dea183727349#file-enterzone-ts

import { NgZone } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Consequent operators and subscriptions are executed inside change detection.
 */
export function enterZone(zone: NgZone) {
  return <T>(source: Observable<T>) => new Observable<T>((observer) => source.subscribe({
    next: (x) => zone.run(() => observer.next(x)),
    error: (err) => zone.run(() => observer.error(err)),
    complete: () => zone.run(() => observer.complete()),
  }));
}
