import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ContextMenuDirective } from './contextMenu.directive';
import { ContextMenuService } from './contextMenu.service';

@NgModule({
  declarations: [
  ContextMenuDirective,
  ],
  exports: [
  ContextMenuDirective,
  ],
  imports: [
  CommonModule,
  ],
  })
export class ContextMenuModule {
  static forRoot(): ModuleWithProviders<ContextMenuModule> {
    return {
      ngModule: ContextMenuModule,
      providers: [
        ContextMenuService,
      ],
    };
  }

  static forChild(): ModuleWithProviders<ContextMenuModule> {
    return {
      ngModule: ContextMenuModule,
    };
  }
}
