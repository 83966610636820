/* eslint-disable no-shadow */
/* eslint-disable dot-notation */
import { isPlatformBrowser } from '@angular/common';
import {
  ElementRef, Inject, Injectable, PLATFORM_ID,
} from '@angular/core';
import appboy from '@braze/web-sdk';
import {
  combineLatest, EMPTY, interval, merge, Observable, Subject,
} from 'rxjs';
import {
  combineAll, map, shareReplay, startWith, switchMap, tap,
} from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { BUILD_SOURCE } from 'src/ver';

interface ILogPurchase {
  days_scheduled: number;
  first_eater: boolean;
  MOV?: number;
  discount_type?: any;
  discount_value?: any;
}

export enum EventName {
  Login = 'login',
  ViewedPromoPerformancePage = 'viewed_promo_performance_page',
  ViewedChoosePromoPage = 'viewed_choose_promo_page',
  ViewedCreatePromoPage = 'viewed_promo_create_page',
  ViewedDashboard = 'viewed_dashboard',
  ViewedOrdersPage = 'viewed_orders_page',
  ViewedReviewsPage = 'viewed_reviews_page',
  ViewedPaymentsPage = 'viewed_payments_page',
  ViewedReportsPage = 'viewed_reports_page',
  ViewedInvoicePage = 'viewed_invoice_page',
  PromoStopped = 'promo_stopped',
  PromoCancelled = 'promo_cancelled',
  MenuDealStopped = 'menu_item_discount_stopped',
  MenuDealCancelled = 'menu_item_discount_cancelled',

  ViewedAnalyticsPage = 'viewed_analytics_page', // used on an old page??

  // ViewedMenuItemDiscountPage = 'viewed_menu_item_discount_page', // not being used anywhere?
  // PromoEnded = 'promo_ended', //server-side

  // The below have been replaced by ViewedCreatePromoPage with a promo_type property
  // ----------------------------------------------------------------------------------
  // ViewedCreateFreeDeliveryPage = 'viewed_create_free_delivery_page',
  // ViewedCreateDiscountOffFoodPage = 'viewed_create_discount_off_food_page',
  // ViewedCreateMenuItemDiscountPage = 'viewed_create_menu_item_discount_page',
  // ViewedCreateBuy1Get1FreePage = 'viewed_create_buy_1_get_1_free_page',
}

@Injectable({
  providedIn: 'root',
  })
export class BrazeService {
  private contentCard$: Observable<number> = null;
  private userOpenedContentCards$ = new Subject<boolean>();
  private unviewedCardCount$: Observable<number>;
  private appboy: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) {
      this.unviewedCardCount$ = EMPTY;
      return;
    }

    // console.log("Constructing BrazeService"); //maing sure only one instance is injected

    // import('@braze/web-sdk').then((inst:any) => this.setupBraze(inst));
    this.setupBraze();
  }

  private setupBraze() {
    // this.appboy = inst;

    appboy.initialize(environment.braze.apiKey, {
      baseUrl: environment.braze.baseUrl,
      enableLogging: environment.braze.debug,
      appVersion: BUILD_SOURCE,
      allowCrawlerActivity: false,
      // doNotLoadFontAwesome: true,
    });
    appboy.display.automaticallyShowNewInAppMessages(); // web socket (or long polling)?
    appboy.openSession();

    // appboy.subscribeToInAppMessage(msg => {
    //   appboy.display.showInAppMessage(msg, elm.nativeElement)
    // })

    this.unviewedCardCount$ = new Observable<number>((sub) => {
      const subscription = appboy.subscribeToContentCardsUpdates((update) => {
        const count = update.getUnviewedCardCount();
        // console.log("BRAZE updates:", count)
        sub.next(count);
      });
      const timer = setInterval(() => {
        appboy.requestContentCardsRefresh();
      }, 65000);
      sub.next(appboy.getCachedContentCards().getUnviewedCardCount());
      return () => {
        clearInterval(timer);
        appboy.removeSubscription(subscription);
      };
    });
  }

  getUnviewedContentCardCountObs() {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return EMPTY; }
    if (!this.contentCard$) {
      const count$ = this.unviewedCardCount$.pipe(
        tap((count) => console.log('BRAZE UnviewedCardCount:', count)),
      );

      const reset$ = this.userOpenedContentCards$.pipe(
        map((val) => 0),
        tap((val) => console.log('Braze user reset')),
      );

      this.contentCard$ = merge(count$, reset$).pipe(
        shareReplay(1),
      );
    }

    return this.contentCard$;
  }

  idUser(id: string) {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return; }
    appboy.changeUser(id);
  }

  showContentCards(elm?: ElementRef, filter?: any) {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return; }
    if (elm?.nativeElement) {
      appboy.display.showContentCards(elm.nativeElement, filter);
    } else {
      appboy.display.showContentCards(); // elm.nativeElement
      this.userOpenedContentCards$.next(true);
    }
  }
  hideAllContentCards() {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return; }
    appboy.display.hideContentCards();
  }

  showInAppMessage(elm: ElementRef) {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return; }
    appboy.display.showInAppMessage(elm.nativeElement);
  }

  logEvent(name: EventName, props?: object) {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return; }
    appboy.logCustomEvent(name, props);
  }

  logPurchase(type: 'free_delivery'|'discount_off_food'|'free_incentive_promo'|'menu_deal'|'buy_1_get_1_free', quantity: number, promoDays: number, minSpend: number, firstTimeCustomer: boolean, discountType: 'amount'|'percentage' = null, discountValue: number = null) {
    if (!isPlatformBrowser(this.platformId) || !environment.braze.enable) { return; }
    const props: ILogPurchase = {
      days_scheduled: promoDays,
      first_eater: firstTimeCustomer,
    };
    if (type !== 'menu_deal') {
      props.MOV = minSpend;
    }
    if (discountType) {
      props.discount_type = discountType;
      props.discount_value = discountValue;
    }
    appboy.logPurchase(type, 0, 'ZAR', quantity, props);
  }
}
