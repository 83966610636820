import { Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, FormArray, FormControl } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { formatDate } from '@angular/common';
import { NativeDateAdapter } from '@angular/material/core';
import { Observable } from 'rxjs';
import { IErrorResponse } from './global';
import { PayPeriodType } from './finance';
import { IPayPeriod } from './orders';

export const setReactiveFormFieldErrors = (form: UntypedFormGroup, res: HttpErrorResponse) => {
  const body = res.error as IErrorResponse;
  if (!body) { return {}; }
  const map = body.error?.error_map;
  if (!map) { return {}; }

  const unhandledErrors: {[key: string]: string[]} = {};
  let unhandledErrorsFound = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(map)) {
    if (!form.contains(key)) {
      unhandledErrors[key] = value;
      unhandledErrorsFound = true;
      // eslint-disable-next-line no-continue
      continue;
    }
    form.controls[key].setErrors({ server: value.join(' ') });
  }
  return unhandledErrorsFound ? unhandledErrors : null;
};

export const getErMsg = (res: HttpErrorResponse): string => {
  const body = res.error as IErrorResponse;
  if (!body) { return ''; }

  return body.error.friendly_message;
};

export const getErMsgX = (obj: any) => {
  if (!obj) { return '?'; }
  let msg: string;

  const erMap: {[key: string]: string[]} = obj.error?.error?.error_map;
  if (erMap) {
    msg = Object.entries(erMap).map(([field, msgs]) => `[${field}] ${msgs.join(', ')}`).join(' | ');
    if (msg) { return msg; }
  }

  const devMsg: string = obj.error?.error?.developer_message;
  if (devMsg) { return devMsg; }

  const friendMsg: string = obj.error?.error?.friendly_message;
  if (friendMsg) { return friendMsg; }

  return obj.message ?? 'Unknown error';
};

export const readFileAsArrayBuffer = (file: File) => new Observable<ArrayBuffer>((subscriber) => {
  const reader = new FileReader();
  reader.onerror = () => subscriber.error('Failed to read file.');
  reader.onload = () => {
    subscriber.next(reader.result as ArrayBuffer);
    subscriber.complete();
  };
  reader.readAsArrayBuffer(file);
  return () => reader.abort();
});

export const readFileAsBinaryString = (file: File) => new Observable<string>((subscriber) => {
  const reader = new FileReader();
  reader.onerror = () => subscriber.error('Failed to read file.');
  reader.onload = () => {
    subscriber.next(reader.result as string);
    subscriber.complete();
  };
  reader.readAsBinaryString(file);
  return () => reader.abort();
});

export const readFileAsDataURL = (file: File) => new Observable<string>((subscriber) => {
  const reader = new FileReader();
  reader.onerror = () => subscriber.error('Failed to read file.');
  reader.onload = () => {
    subscriber.next(reader.result as string);
    subscriber.complete();
  };
  reader.readAsDataURL(file);
  return () => reader.abort();
});

export const readFileAsText = (file: File) => new Observable<string>((subscriber) => {
  const reader = new FileReader();
  reader.onerror = () => subscriber.error('Failed to read file.');
  reader.onload = () => {
    subscriber.next(reader.result as string);
    subscriber.complete();
  };
  reader.readAsText(file);
  return () => reader.abort();
});

export const camcase = (s: string) => s.split(/[ :-]/).map((w) => `${w.substring(0, 1).toUpperCase()}${w.substring(1).toLowerCase()}`).join('');

export const dts = (dt: Date) => { // last minute date-to-string for native date inputs
  const plf = new Platform(PLATFORM_ID);
  if (plf.SAFARI) { return dt; }
  const DATE_FORMAT = 'yyyy-MM-dd';
  const s = formatDate(dt, DATE_FORMAT, 'en');
  return s;
};

export const PICK_FORMATS = {
  parse: { dateInput: { day: 'numeric', month: 'short', year: 'numeric' } },
  display: {
    dateInput: { year: 'numeric', month: 'short', day: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export const ppFilterAlgo = (availableDays, d): boolean => {
  let date: any;
  let month: any;
  if (d === null) return false;
  if (d.getDate().toString().length < 2) {
    date = `0${d.getDate().toString()}`;
  } else {
    date = d.getDate().toString();
  }
  if ((d.getMonth() + 1).toString().length < 2) {
    month = `0${(d.getMonth() + 1).toString()}`;
  } else {
    month = (d.getMonth() + 1).toString();
  }
  const day = `${d.getFullYear().toString()}-${month}-${date}`;
  return availableDays.some((v) => v.includes(day));
};

export const filterDates = (data, date) => {
  const time = new Date(date).getTime();
  const paid = PayPeriodType.Paid;
  const created = PayPeriodType.Created;
  const status = data.filter((pp: IPayPeriod) => pp.status === paid || pp.status === created);
  let finalDate;
  if (status.length > 0) {
    for (let t = 0; t <= status.length - 1; t++) {
      const sDate = new Date(status[t].end_date * 1000);
      const d1 = sDate.getTime();
      const d2 = new Date(new Date(sDate).setDate(new Date(sDate).getDate() + 1)).getTime();
      const d3 = new Date(new Date(sDate).setDate(new Date(sDate).getDate() + 2)).getTime();
      const d4 = new Date(new Date(sDate).setDate(new Date(sDate).getDate() + 3)).getTime();
      if (sDate.getDay() <= 3) {
        if (time === d1 || time === d2 || time === d3) {
          finalDate = sDate;
        }
      } else if (time === d1 || time === d2 || time === d3 || time === d4) {
        finalDate = sDate;
      }
    }
  }
  return finalDate;
};

export const findInvalidControls = (form) => {
  const invalid = [];
  Object.keys(form.controls).forEach((name) => {
    if (form.controls[name].invalid) {
      invalid.push(name);
    }
  });
  return invalid;
};
export const numberOnly = (event): boolean => {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

/**
 * Converts a date into an appropriate Epoch number
 * to comply with 'start' and 'end' date fields on various API end points
 * (mostly used on the date pickers on the various components e.g Payments, Reports and Invoices)
 * @param d - a raw date string being passed in
 * @param setEndOfDay - optional bool to return Epoch which INCLUDES end of day '23:59:59'
 * @returns Epoch number with reset '00:00:00' time or with '23:59:59'
 */
export const dateToEpoch = (d, setEndOfDay: boolean = false): number => {
  if (!setEndOfDay) return new Date(d).setHours(0, 0, 0, 0) / 1000;
  const date = new Date(d);
  const [Y, M, D, h, m, s] = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  const res = new Date(Y, M, D + 1, 0, 0, -1);
  return new Date(res).getTime() / 1000;
};

export const UTCToEpoch = (s: Date) => {
	// const d = s.toString();
	// const strReset = d.substring(0, d.indexOf("GMT") + 3);
	// return new Date(strReset).setUTCHours(0,0,0,0) / 1000;
	const utc = new Date(s).toUTCString();
	return Math.floor(new Date(utc).valueOf() / 1000);
};

export const isEmail = (s) => s.includes('@') || s.includes('.');

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "windows";
  }

  if (/android/i.test(userAgent)) {
      return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "ios";
  }
  return "unknown";
}

export function secondsToHms(d) {
	d = Number(d);
	const h = Math.floor(d / 3600);
	const m = Math.floor(d % 3600 / 60);
	const s = Math.floor(d % 3600 % 60);
	return `${pad0(h, 2)}:${pad0(m, 2)}:${pad0(s, 2)}`;
}

function pad0(v,padBy:number) {
	return String(v).padStart(padBy,'0');
}