import {
  Component, OnInit, Input, ContentChild, HostBinding,
} from '@angular/core';
import { InputRefDirective } from '../../directives/input-ref.directive';

/**
 * See https://blog.angular-university.io/angular-ng-content/
 */
@Component({
  selector: 'form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
  })
export class FormRowComponent implements OnInit {
  @Input() focusClass: string;

  @ContentChild(InputRefDirective)
  input: InputRefDirective;

  // To add a class `focus` to the component template base
  // @HostBinding("class.focus")
  // get focus() {
  //   return this.input ? this.input.focus : false;
  // }

  get classes() {
    const cssClasses = {
      // 'optional-base-class': true
    };
    if (this.input?.focus) { cssClasses[this.focusClass] = true; }
    return cssClasses;
  }
  constructor() { }

  ngOnInit(): void {
  }
}
