import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'restaurantRatingCount',
  })
export class RestaurantRatingCountPipe implements PipeTransform {
  transform(count: number): string {
    if (!count) { return '0'; }
    let result = 0;
    if (count > 10000) {
      result = 10000;
    } else if (count > 1000) {
      result = (Math.floor((count - 1) / 1000)) * 1000;
    } else if (count > 500) {
      result = (Math.floor((count - 1) / 100)) * 100;
    } else if (count > 50) {
      result = (Math.floor((count - 1) / 50)) * 50;
    }
    if (result > 0) {
      return `${result}+`;
    }
    return '50 or less';
  }
}
