import { Component, OnInit } from '@angular/core';
import { DialogConfig } from 'src/app/modules/dialog/dialog-config';
import { DialogRef } from 'src/app/modules/dialog/dialog-ref';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  })
export class TermsAndConditionsComponent implements OnInit {
  isListVisible: boolean = true;
  constructor(
    public config: DialogConfig<any>,
    public dialog: DialogRef<boolean>,
  ) {

  }

  close(): void {
    this.dialog.close(true);
  }

  ngOnInit(): void {
  }
}
