<div *ngIf="busy" class="body-loading">
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>

<div class="page-login">

  <!-- Page Body Here -->

    <div class="landing-body">
        <div class="container flex">

            <div class="col left">
                <div class="intro">
                    <div class="title">
                        <a href="" class="site-logo" id="lnkLogoHome">
                            <img src="assets/logo-square_2023.svg" alt="mrd-rssp">
                        </a>
                        <h4>Sign in</h4>
                    </div>
                    <a class="link" href="https://docs.google.com/forms/d/e/1FAIpQLSeQhh8m6ECZy2H8KRUwcfaciVq-njX52yrs9LBFAOlS4qo9sA/viewform?usp=sf_link" target="_blank">Request Access</a>
                </div>

                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="form-wrapper">
                        <div *ngIf="error" class="form-error" role="alert">
                            {{error}}
                            <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQhh8m6ECZy2H8KRUwcfaciVq-njX52yrs9LBFAOlS4qo9sA/viewform?usp=sf_link" target="_blank">Request access.</a> -->
                        </div>

                        <form-row focusClass="focused">
                            <input appInputRef type="email" formControlName="email" id="inputEmail" placeholder=" " autofocus inputmode="email" autocapitalize="off" autocorrect="off" autocomplete="email">
                            <label for="inputEmail">Email address</label>
                            <div *ngIf="submitted && form.controls.email.errors" class="frm_error">
                              <div *ngIf="form.controls.email.errors.required">Please enter your email</div>
                              <div *ngIf="form.controls.email.errors.email">Invalid email</div>
                            </div>
                        </form-row>

                        <form-row focusClass="focused">
                          <input appInputRef [type]="showPassword ? 'text' : 'password'" formControlName="password" id="inputPassword" placeholder=" " autocomplete="current-password">
                          <label for="inputPassword">Password</label>
													<span class="eye" (click)="showPassword = !showPassword">
														<i class="mdi" [ngClass]="{ 'mdi-eye-off-outline' : showPassword, 'mdi-eye-outline': !showPassword }"></i>														
													</span>
													<!--
														<i class="mdi mdi-eye-off-outline"></i>
														<i class="mdi mdi-eye-outline"></i>
													-->
                          <div *ngIf="submitted && form.controls.password.errors" class="frm_error">
                            <div *ngIf="form.controls.password.errors.required">Please enter your password</div>
                          </div>
                        </form-row>

                        <div class="form-row submit buttons">
                            <p>
                                By signing in you agree to the <a href="#tcs">Terms of Use</a> and <a href="https://www.mrdfood.com/privacy-policy-restaurant-partner" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                            </p>
                            <button class="btn btn-dark" type="submit" id="btnSignIn" [disabled]="busy">
                                <div class="form-loader" role="status" *ngIf="busy">
                                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                                Sign In
                            </button>

                            <span class="form-link">
                                <a routerLink="/forgot-password" [queryParams]="{'target':targetPath}" id="lnkForgotPassword">Forgot password?</a>
                            </span>
                        </div>

                        <div *ngIf="errorGoogle" class="form-error" role="alert">
                            {{errorGoogle}}
                            <br>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQhh8m6ECZy2H8KRUwcfaciVq-njX52yrs9LBFAOlS4qo9sA/viewform?usp=sf_link" target="_blank">Request access</a>
                        </div>
                        <div class="form-row google buttons">
                            <!-- <span><span>or</span></span> -->
                            <button type="button" class="btn btn-light" id="btnSignInGoogle" (click)="loginGoogle()">
                                <span><img src="assets/google.png" alt="mrd-rssp"></span>
                                Sign in with google
                            </button>
                        </div>
                        <!-- <div class="form-row google buttons">
                            <a class="btn btn-light" href="https://docs.google.com/forms/d/e/1FAIpQLSeQhh8m6ECZy2H8KRUwcfaciVq-njX52yrs9LBFAOlS4qo9sA/viewform?usp=sf_link" target="_blank">Request Access</a>
                        </div> -->
                        <div class="form-link">
                            <a class="faq-link mobile" routerLink="faq" target="_blank" rel="noopener noreferrer">Get answers to frequently asked questions here.</a>
                        </div>

                    </div>

                </form>
            </div>

            <div class="col right">
                <div class="landing-image">
                    <img src="assets/login-promo-adverts-2.png" alt="image">
                </div>
                <article>New</article>
                <h6>Start Advertising with Sponsored listings now available</h6>
                <p>
                    Drive traffic to your store by creating sponsored ads.<br>
                    Boost your store's sales using the Advertising tab on the Restaurant Portal and create a sponsored ad.
                </p>
                <a class="faq-link desktop" routerLink="faq" target="_blank" rel="noopener noreferrer">Get answers to frequently asked questions here.</a>
            </div>

        </div>
    </div>
</div>
