
<!-- <div class="page-loading" @fade *ngIf="(loadingGlobal$ | async) !== false">
	<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div> -->
<!-- SAVED THESE CONDITIONS JUST INCASE -->
<!-- *ngIf="(loading$ | async) === null || (loadingGlobal$ | async) !== false" -->
<!-- *ngIf="loadProgressWidth > 0 || showVerifyingProfile" -->
<div *ngIf="updateAvailalbe$ | async">New app version ready! Please refresh to make use of the latest features.</div>
<div *ngIf="loadProgressWidth > 0" [style.max-width.%]="loadProgressWidth" class="task-progress"><!-- {{loadProgressWidth}} --></div>

<div *ngFor="let t of toasts$ | async" [class]="'toast-flash '+t.type">
	<div class="toast-inner">
		<span *ngIf="t.type === 'error'" class="mdi mdi-close"></span>
		<span *ngIf="t.type === 'success'" class="mdi mdi-check"></span>
		{{t.msg}}
	</div>
</div>

<div sidebar *ngIf="showNavForSession" class="sidebar"></div>

<div [class.blue-bg]="blueBg$ | async" class="content">
	<div impersonation></div>

	<div id="fixedHeader" *ngIf="showRestaurantSelect || routeTabs">
		<div restaurantselect 
			*ngIf="showRestaurantSelect" 
			[hideCreatePromoBtn]="hideCreatePromoBtn" 
			class="toolbar"></div>
		<div navtabs *ngIf="routeTabs" [tabData]="routeTabs" [buttonType]="tabBarButton"></div>
		<div *ngIf="!(online$ | async)">Offline</div>
	</div>
	<!-- ADDS SPACING TO NORMAL DOCUMENT FLOW -->
	<div id="spaceGhost" *ngIf="showRestaurantSelect || routeTabs">
		<div restaurantselect 
			*ngIf="showRestaurantSelect" 
			class="toolbar" 
			[hideCreatePromoBtn]="hideCreatePromoBtn"></div>
		<div navtabs *ngIf="routeTabs" [tabData]="routeTabs" [buttonType]="tabBarButton"></div>
		<div *ngIf="!(online$ | async)">Offline</div>
	</div>
	<!-- ^^ Everything in here is hidden from view -->

	<div *ngIf="(loading$ | async) && (loadingGlobal$ | async) === false" @fade class="body-loading">
		<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
	</div>

	<div class="wrapper" [class.noWrapper]="showRestaurantSelect">

		<ng-container *ngIf="!showLogin && !showVerifyingProfile">
			<!-- <div id="bell" (click)="showContentCards()">
				<span id="count" userColor="backgroundColor" *ngIf="unviewedCardCount">{{unviewedCardCount}}</span>
				<span class="mdi mdi-bell" userColor *ngIf="unviewedCardCount"></span>
				<span class="mdi mdi-bell" *ngIf="!unviewedCardCount"></span>
			</div> -->
		</ng-container>

		<div class="routeContainer">
			<router-outlet></router-outlet>
		</div>

	</div>
</div>

<div bottom-nav *ngIf="showNavForSession && showNavForRoute"></div>
