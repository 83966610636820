import {
  Component, OnInit, OnDestroy, Output,
  EventEmitter, Input, Inject,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IdName } from 'src/app/entities/global';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ContextMenuService } from 'src/app/modules/context-menu/contextMenu.service';
import { FirebaseAnalyticsService } from 'src/app/services/firebase_analytics.service';

export interface ITimePeriod extends IdName {
  interval: 'hourly' | 'daily' | 'weekly' | 'monthly';
  start: Date;
  end: Date;
}

export const options = (() => {
  const now = new Date();
  const [Y, M, D, h, m, s] = [
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
  ];
  return [
    // from today at 00:00:00 to the current time of data request
    {
      id: 'today',
      name: 'Today',
      interval: 'hourly',
      start: new Date(Y, M, D, 0, 0, 0),
      end: new Date(Y, M, D + 1, 0, 0, -1),
    },

    // yesterday from 00:00:00 to 23:59:59 - the whole yesterday
    {
      id: 'yesterday',
      name: 'Yesterday',
      interval: 'hourly',
      start: new Date(Y, M, D - 1, 0, 0, 0),
      end: new Date(Y, M, D, 0, 0, -1),
    },

    // from the day (a week before) at 00:00:00 to yesterday 23:59:59 - last 7 full days
    {
      id: 'last_7days',
      name: 'Last 7 days',
      interval: 'daily',
      start: new Date(Y, M, D - 6, 0, 0, 0),
      end: new Date(Y, M, D + 1, 0, 0, -1),
    },

    // from the day (a month before) at 00:00:00 to yesterday 23:59:59 - last 30 full days
    {
      id: 'last_30days',
      name: 'Last 30 days',
      interval: 'weekly',
      start: new Date(Y, M - 1, D - 1, 0, 0, 0),
      end: new Date(Y, M, D + 1, 0, 0, -1),
    },

    // from 1st day of this month at 00:00:00 to today at time of data request
    {
      id: 'month_so_far',
      name: 'Month so far',
      interval: 'daily',
      start: new Date(Y, M, 1, 0, 0, 0),
      end: new Date(Y, M, D + 1, 0, 0, -1),
    },

    // From first day of last month at 00:00:00 to the last day of last month at 23:59:59
    {
      id: 'last_month',
      name: 'Last month',
      interval: 'weekly',
      start: new Date(Y, M - 1, 1, 0, 0, 0),
      end: new Date(Y, M, 1, 0, 0, -1),
    },

    // From first day (of two months ago) at 00:00:00 to the last day of last month at 23:59:59
    {
      id: 'last_2months',
      name: 'Last 2 months',
      interval: 'weekly',
      start: new Date(Y, M - 2, 1, 0, 0, 0),
      end: new Date(Y, M, 1, 0, 0, -1),
    },

    // From first day (of three months ago) to the last day of last month at 23:59:59
    {
      id: 'last_3months',
      name: 'Last 3 months',
      interval: 'weekly',
      start: new Date(Y, M - 3, 1, 0, 0, 0),
      end: new Date(Y, M, 1, 0, 0, -1),
    },
  ] as ITimePeriod[];
})();

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss'],
  })
export class PeriodSelectorComponent implements OnInit, OnDestroy {
  _selected: ITimePeriod;

  @Input() set selected(periodId: string) {
    this._selected = options.find((opt) => opt.id === periodId);
    this.form.controls.date.setValue(this._selected?.id || '');
  }

  @Output() onSelect = new EventEmitter<ITimePeriod>();

  form = new UntypedFormGroup({
    date: new UntypedFormControl(''),
  });

  selection: ITimePeriod[] = options;

  private subFormDate: Subscription;

  constructor(
    private menu: ContextMenuService,
    private ngFA: FirebaseAnalyticsService,
  ) {

  }

  ngOnInit(): void {
    this.subFormDate = this.form.controls.date.valueChanges
      .pipe(
        filter((periodId) => !!periodId),
      )
      .subscribe((periodId) => {
        this.onSelect.emit(options.find((opt) => opt.id === periodId));
      });
  }

  tap(item: ITimePeriod) {
    this.ngFA.logEvent('tap_date_range_selector', { date_range_value: item.id });
  }

  showOptions() {
    this.menu.openContextMenu('timePeriods');
  }

  ngOnDestroy() {
    this.subFormDate.unsubscribe();
  }
}
