<!-- Modal -->
<div
  modal
  [(show)]="isListVisible"
  align="right"
  class="terms-conditions-modal"
>
  <span subtitle> RESTAURANT PORTAL-TERMS OF USE </span>
  <div class="modal-body">
    <ol>
      <li>
        <strong>INTRODUCTION</strong>
        <ol>
          <li>
            These terms of use govern the terms and conditions on which you may
            access and use the Portal(“<strong>Terms of Use</strong>”), which is
            made available by Mr Delivery (Pty) Ltd t/a Mr D Food, a company
            incorporated in South Africa with its registered address at 12th
            floor, 10 Rua Vasco da Gama Plain, Foreshore, Cape Town (“<strong
              >Mr D Food</strong
            >”, “<strong>we</strong>”, “<strong>us</strong>” and
            “<strong>our</strong>”).
          </li>
          <li>
            These Terms of Use are binding on and enforceable against every
            person that registers and/or logs in as, or on behalf of, a
            Restaurant Partner as set out in clause 3
            below(“<strong>you</strong>” or “<strong>your</strong>”), as well as
            binding on and enforceable against each such Restaurant Partner(also
            “<strong>you</strong>” or “<strong>your</strong>”, as the context
            requires). By logging in to the Portal, you acknowledge that:

            <ol>
              <li>
                you have read and agree to be bound by these Terms of Use, and
                that you must not use the Portal if you do not agree to the
                terms contained herein;
              </li>
              <li>
                the purpose of these Terms of Use is to, inter alia, regulate
                your access to and use of the Portal, including Restaurant
                Partners accessing and utilizing the Ad Services; and
              </li>
              <li>
                these Terms of Use also further regulate and/or introduce
                provisions relating to the protection of personal information.
              </li>
            </ol>
          </li>
          <li>
            These Terms of Use must be read with the provisions of the
            Restaurant Agreement. Should there be any inconsistency between
            these Terms of Use and the provisions of the Restaurant Agreement,
            the provisions of the Restaurant Agreement shall prevail to the
            extent of such inconsistency save where otherwise provided herein.
            In particular and for the avoidance of doubt, the terms herein which
            provide for:
            <ol>
              <li>
                the protection of personal information, shall prevail over the
                Restaurant Agreement to the extent of any inconsistency; and
              </li>
              <li>
                Restaurant Partners accessing and utilizing the Ad Services,
                shall prevail over the Restaurant Agreement to the extent of any
                inconsistency.
              </li>
            </ol>
          </li>
          <li>
            Should you be a natural person or a juristic person with an asset
            value or annual turnover of less than the threshold value determined
            by the Minister from time to time, in terms of section 6 of the
            Consumer Protection Act 2008 (“<strong>CPA</strong>”) (which is R2
            million as at February 2017), please take special note of the
            following clauses: 1.2, 1.5, 3.3, 3.4, 3.6, 4, 6, 7, 8, 10, 11, 12,
            13.2 and 15.
          </li>
          <li>
            If the CPA applies to a Restaurant Partner under these Terms of Use
            as set out in clause 1.4 above, nothing herein is intended to:
            <ol>
              <li>
                waive or deprive the Restaurant Partner of a right in terms of
                the CPA;
              </li>
              <li>
                avoid Mr D Food’s obligation or duty in terms of the CPA; or
              </li>
              <li>
                authorise Mr D Food to do anything that is unlawful in terms of
                the CPA or fail to do anything that is required in terms of the
                CPA.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>DEFINITIONS</strong>
        <div style="margin-top: 16px">
          In addition to the definitions contained above, in these Terms of Use,
          unless the context indicates otherwise:
        </div>
        <ol>
          <li>
            “<strong>Ad Interaction Event(s)</strong>” means the user action
            taken while Sponsored (App) Ads or Sponsored (EAN) Ads are being
            served, which includes:
            <ol>
              <li>
                clicking the particular Sponsored (App) Ad or Sponsored (EAN) Ad
                which redirects the user to the relevant corresponding result(s)
                on the MRD Platform, as the case may be;
              </li>
              <li>
                adding any Restaurant Partner-provided products to the user’s
                cart on the MRD Platform;
              </li>
              <li>
                purchasing any Restaurant Partner-provided products on the MRD
                Platform; or
              </li>
              <li>all of the previous stated actions;</li>
            </ol>
          </li>
          <li>
            “<strong>Ad Platform</strong>” means the online interfaces and
            related tools and systems Mr D Food makes available to you on the
            Portal;
          </li>
          <li>
            “<strong>Ad Revenue</strong>” means the purchase price of the
            Restaurant Partner-provided products on the MRD Platform and paid
            for by customers, which resulted from an Ad Interaction Event within
            the Attribution Window;
          </li>
          <li>
            “<strong>Ad Services</strong>” has the meaning ascribed thereto in
            clause 6.1 below, and as further contemplated throughout clause 6
            generally;
          </li>
          <li>
            “<strong>Ad Wallet</strong>” means the designated account on the Ad
            Platform in terms of which the Restaurant Partner can credit its
            account for the purposes of running Sponsored (App) Ad or Sponsored
            (EAN) Ad campaign(s);
          </li>
          <li>
            “<strong>Affiliate(s)</strong>”, with respect to any entity, means
            any other entity that directly or indirectly controls, is controlled
            by, or is under common control with such entity;
          </li>
          <li>
            “<strong>Attribution</strong>” means the process of allocating Ad
            Interaction Events to Sponsored (App) Ad or Sponsored (EAN) Ad
            campaign(s) within an Attribution Window;
          </li>
          <li>
            “<strong>Attribution Window</strong>” means a rolling 14-day period
            which commences after an Ad Interaction Event has occurred, during
            which period Ad Interaction Events are attributed to Sponsored (App)
            Ad or Sponsored (EAN) Ad campaigns in order to determine the ROI;
          </li>
          <li>
            “<strong>Business Day</strong>” means any day other than a Saturday,
            Sunday or public holiday in South Africa;
          </li>
          <li>
            “<strong>CPC(s)</strong>” means cost incurred per click on either
            the corresponding Product Listing of the Sponsored (App) Ad or
            Sponsored (EAN) Ad, or the add-to-cart on such corresponding Product
            Listing of the Sponsored (App) Ad or Sponsored (EAN) Ad, or on the
            other corresponding result(s) on the MRD Platform of the Sponsored
            (App) Ad or Sponsored (EAN) Ad;
          </li>
          <li>
            “<strong>including</strong>” means ‘including without limitation’,
            and “<strong>includes</strong>” means ‘includes without limitation’;
          </li>
          <li>
            “<strong>Loss</strong>” means any and all loss, expense or damage
            that a person (natural or juristic) may suffer or incur (including
            any third party claims which may be made against it), of whatsoever
            nature including direct, indirect, consequential or special damages;
          </li>
          <li>
            “<strong>MRD Platform</strong>” means the website operated by Mr D
            Food having the URL www.mrdfood.com as well as related ‘Mr D Food’
            mobi-sites and software applications, and which is used by customers
            for the ordering and purchase of food, beverages and other products
            from Restaurant Partners;
          </li>
          <li>
            “<strong>Parties</strong>” means you and Mr D Food, and
            “<strong>Party</strong>” means any one of them, as the context
            requires;
          </li>
          <li>
            “<strong>Portal</strong>” means the online portal and tools made
            available by Mr D Food to you under these Terms of Use, including:
            <ol>
              <li>
                an online electronic data interchange system, as an alternative
                system to any other electronic data interchange system used by
                Mr D Food to communicate with you under the Restaurant
                Agreement, and which alternative system allows you to respond to
                product- related queries and other action items from Mr D Food
                and ensure that your Restaurant Partner Details are continually
                updated; and
              </li>
              <li>the Ad Services;</li>
            </ol>
          </li>
          <li>
            "<strong>Product Listing(s)</strong>” means the corresponding
            product listing(s) on the MRD Platform;
          </li>
          <li>
            “<strong>Promotional Offer(s)</strong>” means promotional campaigns
            made available by Mr D Food on the Portal, but specifically
            excluding Sponsored (App) Ads and Sponsored (EAN) Ads, and including
            as further contemplated throughout clause 6 generally;
          </li>
          <li>
            “<strong>Promotional Offer Details</strong>” means the details
            required to be completed by you on the Portal to apply for and
            launch a Promotional Offer;
          </li>
          <li>
            “<strong>Promotional Offer Pricing List</strong>” means the then
            current-latest pricing schedule for Promotional Offers, as published
            on the Portal by Mr D Food (in its sole discretion) from time to
            time, and as further described in clause 6.4 below;
          </li>
          <li>
            “<strong>Restaurant Agreement</strong>” means the written agreement
            concluded by the particular Restaurant Partner with Mr D Food in
            terms of which, inter alia, the Restaurant Partner appoints Mr D
            Food as its sales platform and delivery agent;
          </li>
          <li>
            “<strong>Restaurant Partner</strong>” means the corresponding
            restaurant, retail outlet, liquor store, pharmacy or other
            food/snack outlet which has a binding Restaurant Agreement in place
            with Mr D Food;
          </li>
          <li>
            “<strong>Restaurant Partner Details</strong>” means the details
            required to be completed on the Portal to apply for and register a
            Restaurant Partner account on the Portal, including all requisite
            Restaurant Agreement details;
          </li>
          <li>
            “<strong>ROI(s)</strong>” means the Restaurant Partner’s return on
            advertising investment, which is the total Ad Revenue generated by
            Sponsored (App) Ad and Sponsored (EAN) Ad campaign(s) in aggregate,
            divided by the advertising spend incurred (excluding VAT) by the
            Sponsored (App) Ad and Sponsored (EAN) Ad campaign(s) in aggregate;
          </li>
          <li>
            “<strong>Sponsored (App) Ad(s)</strong>” means product-targeted
            advertising (including at the Product Listing level), and/or
            Restaurant Partner-level targeted advertising that appears on the
            MRD Platform and otherwise as predetermined and preapproved by Mr D
            Food (in its sole discretion) from time to time, and as further
            contemplated throughout clause 6 generally;
          </li>
          <li>
            “<strong>Sponsored (EAN) Ad(s)</strong>” means product-targeted
            advertising (including at the Product Listing level), and/or
            Restaurant Partner-level targeted advertising:
            <ol>
              <li>
                that appears on third-party social media platforms, as
                predetermined, preselected and preapproved by Mr D Food (in its
                sole discretion) from time to time; and
              </li>
              <li>
                which links through to a corresponding result(s) on the MRD
                Platform,
              </li>
            </ol>
            <div>
              and as further contemplated throughout clause 6 generally;
            </div>
          </li>
          <li>
            “<strong>Taxes</strong>” means any and all taxes, duties, levies,
            fees or charges assessed, incurred or required to be collected or
            paid for any reason (whether by the Restaurant Partner, Mr D Food or
            any of Mr D Food’s Affiliates) in connection with any offer or sale
            of Restaurant Partner- provided products by the Restaurant Partner
            in connection with the MRD Platform, or otherwise in connection with
            any act or omission of the Restaurant Partner, your Affiliates or
            your respective employees, agents, contractors or representatives;
            and
          </li>
          <li>
            “<strong>written</strong>” or “<strong>in writing</strong>” includes
            electronic mail, and any electronic message sent by way of the
            Portal.
          </li>
        </ol>
      </li>
      <li>
        <strong>REGISTRATION</strong>
        <ol>
          <li>
            When first accessing the Portal (including accessing and utilizing
            the Ad Services where applicable), you will be prompted to:
            <ol>
              <li>
                complete the Restaurant Partner Details, alternatively at Mr D
                Food’s sole discretion, Mr D Food will complete the Restaurant
                Partner Details on your behalf in accordance with the Restaurant
                Agreement;
              </li>
              <li>make use of a username and password; and</li>
              <li>
                agree to these Terms of Use in your capacity as a duly
                authorised representative of the corresponding Restaurant
                Partner.
              </li>
            </ol>
          </li>
          <li>
            Subject to clause 3.1 above, registration of a Restaurant Partner
            account on the Portal is complete when the Restaurant Partner
            Details are completed, when you have made use of a username and
            password, when you have agreed to these Terms of Use in your
            capacity as a duly authorised representative of the corresponding
            Restaurant Partner, and you have received a corresponding
            confirmation notification on the Portal.
          </li>
          <li>
            You shall immediately notify Mr D Food if any of your Restaurant
            Partner Details on the Portal requires updating, which you will then
            promptly update to ensure accuracy, alternatively at Mr D Food’s
            sole discretion, Mr D Food will update those details on your behalf
            in consultation with you. You warrant that the completed Restaurant
            Partner Details and as updated from time to time, are correct and
            correspond to a Restaurant Partner with a currently binding
            Restaurant Agreement in place, and you acknowledge that Mr D Food is
            not responsible for any Loss suffered or incurred by you arising out
            of or in relation to your Restaurant Partner Details on the Portal
            being incorrect or out-of-date. In order to comply with the
            Electronic Communications and Transactions Act 2002, you must
            provide us with, inter alia, the Restaurant Partner’s legal name,
            trading name and contact information. You must also provide us with
            all other documentation and information reasonably requested by Mr D
            Food for vetting purposes, which includes the name of the person
            acting on behalf of the Restaurant Partner as account owner and any
            permitted designee(s). You hereby authorise us to verify all
            information that you provide to us, and you undertake to provide us
            with written confirmation of such authorisation on written request
            by Mr D Food.
          </li>
          <li>
            Mr D Food is entitled to decide in its sole discretion whether to
            allow registration as, or on behalf of, a Restaurant Partner on the
            Portal. You agree that a Mr D Food operator may call you to confirm
            your Restaurant Partner Details, following your registration on the
            Portal.
          </li>
          <li>
            For security purposes you agree to enter the correct username and
            password whenever accessing the Portal (including accessing and
            utilizing the Ad Services where applicable) on behalf of the
            Restaurant Partner, failing which you will be denied access.
          </li>
          <li>
            <strong> You agree and warrant that: </strong>
            <ol>
              <li>
                <strong>
                  your username and password shall not be disclosed by you to
                  any third party;
                </strong>
              </li>
              <li>
                <strong>
                  once the correct username and password relating to your
                  Restaurant Partner account on the Portal has been entered,
                  irrespective of whether the use of the username and password
                  is unauthorised or fraudulent, you will be liable for any
                  corresponding act or omission on the Portal; and
                </strong>
              </li>
              <li>
                <strong>
                  you will not in any way use any device, software or other
                  instrument to interfere or attempt to interfere with the
                  proper working of the Portal. In addition, you agree that you
                  will not in any way use any robot, spider, other automatic
                  device, or manual process to monitor, copy, distribute or
                  modify the Portal or the information contained therein or
                  herein.
                </strong>
              </li>
            </ol>
          </li>
          <li>
            All accounts registered on the Portal for the same Restaurant
            Partner shall be treated as one account for that Restaurant Partner.
          </li>
        </ol>
      </li>
      <li>
        <strong>AMENDMENTS</strong>
        <ol>
          <li>
            Mr D Food may, in its sole discretion, change these Terms of Use
            (including the Promotional Offer Pricing List, and otherwise in part
            or as a whole), at any time on at least 14 days prior notification
            to you. Subject to the aforesaid:
            <ol>
              <li>
                changes shall be notified to Restaurant Partners via email or
                posted on the Portal;
              </li>
              <li>
                if no effective date is stated, the relevant changes will be
                effective from the date that is 14 days after the relevant
                notification; and
              </li>
              <li>
                it is your responsibility to regularly check the Portal and make
                sure that you are satisfied with any changes. Should you not be
                satisfied, you must no longer use the Portal, and must send us
                written confirmation that you will no longer be using the
                Portal.
              </li>
            </ol>
          </li>
          <li>
            Any such change will only apply to your use of the Portal after the
            effective date of the change. If you use the Portal (including
            accessing and utilizing the Ad Services where applicable) after such
            changes have been notified to you or posted on the Portal, you will
            be deemed to have accepted such changes.
          </li>
        </ol>
      </li>
      <li>
        <strong>RESPONDING VIA PORTAL</strong>
        <div style="margin-top: 16px">
          In addition and related to other tools included in the Portal, the
          Portal is also an electronic data interchange system which enables you
          to respond to product-related queries and other general- Portal action
          items from Mr D Food. It is your responsibility to regularly check the
          Portal and respond to such queries and other action items from Mr D
          Food.
        </div>
      </li>

      <li>
        <strong>AD SERVICES</strong>
        <ol>
          <li>
            Mr D Food will make a variety of tools and services available to you
            through the Ad Platform, to enable the Restaurant Partner to, inter
            alia:
            <ol>
              <li>
                apply for and launch Promotional Offers to be run by that
                Restaurant Partner on the Mr D Platform;
              </li>
              <li>
                participate in the Sponsored (App) Ads or Sponsored (EAN) Ads
                (as the case may be) bidding functionality, in terms of which
                the Restaurant Partner may select to partake in Sponsored (App)
                Ads or Sponsored (EAN) Ads (as the case may be) on a CPC basis,
                subject to there being sufficient purchased credit in that
                Restaurant Partner’s Ad Wallet;
              </li>
              <li>
                set their daily level or campaign level budgets for Sponsored
                (App) Ads or Sponsored (EAN) Ads (as the case may be);
              </li>
              <li>
                temporarily pause Sponsored (App) Ad or Sponsored (EAN) Ad
                campaign(s) (as the case may be); and
              </li>
              <li>
                track the metrics of the Sponsored (App) Ads or Sponsored (EAN)
                Ads (as the case may be),
              </li>
            </ol>
            <div>(collectively the “<strong>Ad Services</strong>”).</div>
          </li>
          <li>
            <strong>Promotional Offers generally</strong>: All Promotional
            Offers are subject to you correctly applying therefor, including by
            completing the Promotional Offer Details. If the Promotional Offer
            Details are not correctly completed, your application therefor will
            automatically be rejected, and you will need to reapply.
            Notwithstanding any provisions to the contrary contained in the
            Restaurant Agreement:
            <ol>
              <li>
                Mr D Food will produce the required creative works for the
                Promotional Offer to be launched, using images provided by you
                to us in terms of the Restaurant Agreement;
              </li>
              <li>
                the format of the creative works is in Mr D Food’s sole
                discretion and you will not be entitled to make any changes
                thereto;
              </li>
              <li>
                the exact positioning of the advertisement will be determined by
                Mr D Food in its sole discretion; and
              </li>
              <li>
                any Promotional Offer start date is approximate only and may
                vary upon written notice by Mr D Food to you.
              </li>
            </ol>
          </li>
          <li>
            <strong>Promotional Offers cancellations</strong>: If, at any time,
            the Restaurant Partner no longer wishes to participate in the
            launched Promotional Offer, the Restaurant Partner can cancel such
            Promotional Offer on the Portal. Furthermore, Mr D Food may (in its
            sole discretion) cancel any Promotional Offer at any time and will
            inform the Restaurant Partner of such cancellation as soon as
            practicably possible.
          </li>
          <li>
            <strong>Promotional Offers Pricing List</strong>: The Promotional
            Offer Pricing List sets out the fees payable by Restaurant Partners
            for Promotional Offers, and is hereby incorporated by reference into
            these Terms of Use. Subject to clause 4 above, Mr D Food may (in its
            sole discretion) change the Promotional Offer Pricing List at any
            time, including by removing or amending existing fees, and by
            introducing new fees. For the avoidance of doubt, the applicable
            Promotional Offer Pricing List will be that version published at the
            time that a particular fee becomes payable by the Restaurant
            Partner. Furthermore, Mr D Food retains the right to stipulate the
            conditions a Restaurant Partner must meet in order to qualify for a
            Promotional Offer discount, and Mr D Food may (in its sole
            discretion) decide when a particular Promotional Offer discount
            expires.
          </li>
          <li>
            <strong>Co-funded Promotional Offers</strong>:
            <ol>
              <li>
                Mr D Food retains the right to pause and/or resume a co-funded
                Promotional Offer at any time throughout the duration of the
                campaign.
              </li>
              <li>
                Mr D Food may, in its sole discretion, decide when a co-funded
                Promotional Offer begins and terminates.
              </li>
              <li>
                Mr D Food retains the right to determine what the co-funded
                contribution percentage will be.
              </li>
              <li>
                Mr D Food retains the right to limit the number of items per
                Restaurant Partner which may be included in a particular
                co-funded Promotional Offer.
              </li>
              <li>
                Participating Restaurant Partners will receive their Mr D Food
                Promotional Offer contribution reflected as a payment made by Mr
                D Food to such participating Restaurant Partner at the end of
                the month in which the co-funded Promotional Offer has run. This
                will be made as part of the regular payment process and will be
                reflected as an additional line item on the remittance.
              </li>
            </ol>
          </li>
          <li>
            <strong>Promotional Offers payment</strong>: Whenever the Restaurant
            Partner is liable to pay or reimburse Mr D Food for accessing and
            utilizing Promotional Offers:
            <ol>
              <li>
                Mr D Food shall be entitled, in its sole discretion: (a) to set
                off any such amounts payable by the Restaurant Partner to us
                against any amounts payable by us to the Restaurant Partner in
                terms of the Restaurant Agreement; (b) to invoice the Restaurant
                Partner, which shall make payment thereof within 10 days of
                receipt of invoice, provided that should the Restaurant Partner
                not make payment timeously, Mr D Food may (in addition to
                pursuing any other remedies available at law to recover such
                amounts) deny and correspondingly block your access to the Ad
                Services and by extension Promotional Offers, Sponsored (App)
                Ads, Sponsored (EAN) Ads, etc., at least until such time as
                payment is made in full; or (c) to seek such payment or
                reimbursement from the Restaurant Partner by any other lawful
                means; and
              </li>
              <li>
                notwithstanding any provisions to the contrary contained in the
                Restaurant Agreement, the Restaurant Partner is not entitled to
                set off any such amounts payable by the Restaurant Partner to Mr
                D Food, without the prior written consent of Mr D Food.
              </li>
            </ol>
          </li>
          <li>
            <strong>Sponsored (App)/Sponsored (EAN) Ads generally</strong>: The
            Restaurant Partner acknowledges that all Sponsored (App) Ads and
            Sponsored (EAN) Ads are subject to all the provisions of these Terms
            of Use, but specifically acknowledges that the quality undertakings
            set out in the Restaurant Agreement, plus the intellectual property
            undertakings set out in the Restaurant Agreement as read with the
            intellectual property undertakings set out herein, apply as well.
          </li>
          <li>
            <strong>Sponsored (App)/Sponsored (EAN) Ads payment</strong>: The
            Restaurant Partner shall pay us fees for accessing and utilizing
            Sponsored (App) Ads and Sponsored (EAN) Ads, and such fees payable
            according to and otherwise based solely on the applicable billing
            metrics published on the Ad Platform from time to time; namely a CPC
            basis, which will be determined by the ROIs or CPCs set by that
            Restaurant Partner on the Ad Platform.
            <strong
              >The Restaurant Partner acknowledges that third parties may
              generate clicks for improper purposes, and the Restaurant Partner
              accepts this risk</strong
            >. Furthermore:
            <ol>
              <li>
                the Restaurant Partner will be able to cancel its Sponsored
                (App) Ad or Sponsored (EAN) Ad campaign(s) through the Ad
                Platform at any time, with immediate effect, but will remain
                liable for the Sponsored (App) Ads and Sponsored (EAN) Ads
                already clicked;
              </li>
              <li>
                we shall deduct the applicable fees due by the particular
                Restaurant Partner for Sponsored (App) Ads and Sponsored (EAN)
                Ads from the Ad Wallet, on a CPC basis from time to time. In
                that regard, whenever such fees are due by the particular
                Restaurant Partner, Mr D Food shall be entitled, in its sole
                discretion: (a) to set off the amounts payable by the Restaurant
                Partner to us against any amounts payable by us to the
                Restaurant Partner; (b) to invoice the Restaurant Partner, which
                shall make payment thereof within 15 days ofreceipt of invoice,
                provided that should the Restaurant Partner not make payment
                timeously, Mr D Food may (in addition to pursuing any other
                remedies available at law to recover such amounts) deny and
                correspondingly block your access to the Ad Services and by
                extension Promotional Offers, Sponsored (App) Ads, Sponsored
                (EAN) Ads, etc., at least until such time as payment is made in
                full; and (c) to seek such payment or reimbursement from the
                Restaurant Partner by any other lawful means; and
              </li>
              <li>
                once a Sponsored (App) Ad or Sponsored (EAN) Ad (as the case may
                be) is cancelled, the Restaurant Partner may request that the
                unspent amount in the Ad Wallet (if any) be refunded, which
                refund will be processed within a reasonable time. In that
                regard: (a) in the case of any erroneous refund to the
                Restaurant Partner, we are entitled to use any of the above
                means to seek reimbursement of the amount refunded erroneously;
                and (b) the Restaurant Partner is not entitled to set off any
                amounts payable by the Restaurant Partner to Mr D Food for
                accessing and utilizing Sponsored (App) Ads and Sponsored (EAN)
                Ads, without the prior written consent of Mr D Food.
              </li>
            </ol>
          </li>
          <li>
            <strong>Ad Wallet Gratuity</strong>: The Restaurant Partner
            acknowledges that Mr D Food may from time to time gratuitously
            credit the Ad Wallet (“Ad Wallet Gratuity”), which will be
            non-refundable and subject to any other terms and conditions
            communicated to the Restaurant Partner relating to such Ad Wallet
            Gratuity.
          </li>
          <li>
            <strong>Time period to dispute invoice</strong>: In the event that
            the Restaurant Partner wishes to dispute any fees or charges
            invoiced by Mr D Food under these Terms of Use (including for
            accessing and utilizing the Ad Services, and by extension
            Promotional Offers, Sponsored (App) Ads, Sponsored (EAN) Ads, etc.),
            the particular Restaurant Partner shall be entitled to raise such a
            dispute within three months of receipt of such invoice. Should the
            period of three months lapse following receipt of the particular
            invoice, such invoice will be final and binding.
          </li>
        </ol>
      </li>
      <li>
        <strong>INTELLECTUAL PROPERTY</strong>
        <ol>
          <li>
            You warrant that:
            <ol>
              <li>
                you are the registered proprietor or licensee of all
                intellectual property provided to Mr D Food under these Terms of
                Use (including in connection with the Ad Services, and by
                extension Promotional Offers, Sponsored (App) Ads, Sponsored
                (EAN) Ads, etc.) (“<strong>Restaurant IP</strong>”);
              </li>
              <li>
                you are to grant Mr D Food the rights to use such Restaurant IP
                in connection with the Ad Services, and by extension Promotional
                Offers, Sponsored (App) Ads, Sponsored (EAN) Ads, etc., and as
                otherwise contemplated herein; and
              </li>
              <li>
                no trademark, copyright, design or other intellectual property
                rights of any third party(ies) will be infringed by the use of
                the Restaurant IP in connection with the Ad Services, and by
                extension Promotional Offers, Sponsored (App) Ads, Sponsored
                (EAN) Ads, etc., and as otherwise contemplated herein,
              </li>
            </ol>
            <div>
              and the corresponding Restaurant Partner, its employees, agents
              and you hereby indemnify Mr D Food and its Affiliates against any
              Loss suffered or incurred by any of them arising from or in
              relation to a breach of any of the aforegoing warranties.
            </div>
          </li>
          <li>
            Should you wish to use any of Mr D Food’s trademarks (whether
            registered or unregistered), branding, marketing material or any
            other form of intellectual property (“<strong>MRD IP</strong>”) for
            purposes of advertising (regardless of media channel used) or for
            use on any website or mobile application:
            <ol>
              <li>
                you must make application to use the MRD IP as directed on the
                Portal and set out details relating to the purpose of the
                advertising, the media channel(s) used, the website or mobile
                application used and for how long you wish to use the MRD IP
                (“<strong>IP Use Application</strong>”);
              </li>
              <li>
                we may in our sole discretion: (a) approve an IP Use Application
                and provide the corresponding Restaurant Partner with the
                creative works and/or place any further conditions, technical
                specifications and/or other restrictions relating to the use of
                the MRD IP, as and when we deem necessary (“<strong
                  >IP Approval</strong
                >”); and (b) revoke the IP Approval at any time, on written
                notice;
              </li>
              <li>
                you warrant that you and the corresponding Restaurant Partner
                will only use the MRD IP in strict conformance with the IP
                Approval; and
              </li>
              <li>
                the corresponding Restaurant Partner, its employees, agents and
                you hereby indemnify Mr D Food and its Affiliates against any
                Loss suffered or incurred by any of them arising from or in
                relation to a breach of the aforegoing warranty.
              </li>
            </ol>
          </li>
          <li>
            In addition to the above and any similar undertakings, warranties
            and indemnities set out elsewhere in these Terms of Use, all
            intellectual property undertakings, warranties and indemnities set
            out in the Restaurant Agreement are hereby incorporated by reference
            into these Terms of Use, mutatis mutandis, and are binding on and
            enforceable against the Restaurant Partner accordingly.
          </li>
        </ol>
      </li>
      <li>
        <strong>CONFIDENTIALITY</strong>
        <div style="margin-top: 16px">
          In addition to any similar undertakings, warranties and indemnities
          set out elsewhere in these Terms of Use, all confidentiality
          undertakings, warranties and indemnities set out in the Restaurant
          Agreement are hereby incorporated by reference into these Terms of
          Use, mutatis mutandis, and are binding on and enforceable against the
          Restaurant Partner accordingly.
        </div>
      </li>
      <li>
        <strong>PERSONAL INFORMATION</strong>
        <ol>
          <li>
            The confidential information that the Restaurant Partner receives
            from Mr D Food (and vice versa, if applicable) may include ‘personal
            information’ (“<strong>PI</strong>”) as defined in the Protection of
            Personal Information Act 2013 (“<strong>POPI</strong>”), possibly
            including the names, delivery or email addresses and/or telephone
            numbers of Mr D Food’s staff and/or customers. In this regard the
            Restaurant Partner undertakes to only use or ‘process’ (as such term
            is defined in POPI) each specific piece of PI to the extent
            necessary for the purposes of performing its express obligations
            under these Terms of Use and in accordance with the provisions of
            POPI. The Restaurant Partner specifically undertakes that it shall:
            <ol>
              <li>
                process PI in such manner that is reasonable, adequate,
                relevant, non-excessive, purpose- specific and non-infringing of
                the relevant individual’s privacy;
              </li>
              <li>
                secure the integrity and confidentiality of PI in its possession
                or under its control by taking appropriate, reasonable technical
                and organisation measures to prevent: (a) loss of, damage to or
                unauthorised destruction of PI; and (b) unlawful access to or
                processing of PI;
              </li>
              <li>
                take reasonable measures to: (a) identify all reasonably
                foreseeable internal and external risks to such PI; (b)
                establish and maintain appropriate safeguards against such
                risks; (c) regularly verify that the safeguards are effectively
                implemented; and (d) ensure that the safeguards are continually
                updated in response to new risks or deficiencies in previously
                implemented safeguards;
              </li>
              <li>
                have due regard to generally accepted information security
                practices and procedures which may apply to it generally or be
                required in terms of specific industry or professional rules and
                regulations;
              </li>
              <li>
                if there are reasonable grounds to believe that any PI has been
                accessed or acquired by an unauthorised person, immediately
                notify Mr D Food thereof; and
              </li>
              <li>
                on reasonable prior notification by Mr D Food to the Restaurant
                Partner, provide Mr D Food with such access to the Restaurant
                Partner’s systems to enable Mr D Food to effectively audit the
                Restaurant Partner’s information security practices and
                procedures to ensure compliance with POPI.
              </li>
            </ol>
          </li>
          <li>
            Similarly, Mr D Food may receive PI from the Restaurant Partner,
            possibly including the names, email addresses and/or telephone
            numbers of the Restaurant Partner’s staff and/or customers. In this
            regard Mr D Food:
            <ol>
              <li>
                undertakes to only use or process each specific piece of PI to
                the extent necessary for the purposes of performing its express
                obligations under these Terms of Use (including to improve the
                Portal or Ad Services, and by extension any related technology),
                and in accordance with the provisions of POPI; and
              </li>
              <li>
                provides the undertakings set out in clauses 9.1.1 to 9.1.5
                above, mutatis mutandis.
              </li>
            </ol>
          </li>
          <li>
            Should the Restaurant Partner wish to enforce its rights under POPI
            to request the description of the Restaurant Partner PI held by Mr D
            Food, and/or request a deletion of the Restaurant Partner PI held by
            Mr D Food, the Restaurant Partner may make such request by sending
            an email to
            <a>restaurantportal@mrdfood.com</a>.
          </li>
        </ol>
      </li>
      <li>
        <strong>ADDITIONAL WARRANTIES AND INDEMNITIES</strong>
        <ol>
          <li>
            By registering and/or logging in as, or on behalf of, a Restaurant
            Partner on the Portal (including accessing and utilizing the Ad
            Services where applicable):
            <ol>
              <li>
                you warrant that you are duly authorised to do so, as a duly
                authorised representative of the corresponding Restaurant
                Partner; and
              </li>
              <li>
                the corresponding Restaurant Partner, its employees, agents and
                you hereby indemnify Mr D Food and its Affiliates against any
                Loss suffered or incurred by any of them arising from or in
                relation to a breach of any of the warranties set out in these
                Terms of Use.
              </li>
            </ol>
          </li>
          <li>
            In addition to your warranties referred to above and elsewhere in
            these Terms of Use, you warrant that:
            <ol>
              <li>
                you have or the corresponding Restaurant Partner has (as the
                case may be) a currently binding Restaurant Agreement in place,
                which is in full force and effect; and
              </li>
              <li>
                you have, alternatively you and the corresponding Restaurant
                Partner respectively have, all requisite right, power and
                authority to apply for and register a Restaurant Partner account
                on the Portal and perform your respective obligations and grant
                the respective rights you grant hereunder,
              </li>
            </ol>
            <div>
              and the corresponding Restaurant Partner, its employees, agents
              and you hereby indemnify Mr D Food and its Affiliates against any
              Loss suffered or incurred by any of them arising from or in
              relation to a breach of any of the aforegoing warranties.
            </div>
          </li>
        </ol>
      </li>
      <li>
        <strong>LIMITATION OF LIABILITY</strong>
        <div style="margin-top: 16px">
          Mr D Food shall not under any circumstances be liable to you for any
          direct, consequential, incidental, indirect, special or punitive
          damages (such as those arising from loss of income, loss of goodwill
          or profits, or business interruption), arising out of these Terms of
          Use or otherwise.
        </div>
      </li>
      <li>
        <strong>TAX POLICIES</strong>
        <ol>
          <li>
            The Restaurants Partner shall be solely responsible for the payment
            of all of the Taxes. If a taxing authority requires us to pay any of
            the Taxes, the Restaurant shall promptly reimburse us for the
            amounts paid.
          </li>

          <li>
            All fees payable by the Restaurant Partner to Mr D under these Terms
            of Use t exclude any applicable Taxes, and the Restaurant Partner
            shall be responsible for paying Mr D all of the Taxes imposed on
            such fees.
          </li>
        </ol>
      </li>
      <li>
        <strong>USE OF PORTAL</strong>
        <ol>
          <li>
            We shall have the right in our sole discretion to determine the
            content, appearance, design, functionality and all other aspects of
            the Portal (including the Ad Platform and/or Ad Services, and
            including the right to re-design, modify, remove and alter the
            content, appearance, design, functionality and all other aspects of,
            and prevent or restrict access to, the Portal as well as any
            element, aspect, portion or feature thereof).
          </li>
          <li>
            The use of the Portal (including the Ad Platform and/or Ad Services)
            is entirely at your own risk and you assume full responsibility for
            any risk or loss resulting from use of or reliance on any
            information on the Portal.
          </li>
          <li>
            Whilst Mr D Food takes reasonable measures to ensure that the
            content of the Portal (including the Ad Platform and/or Ad Services)
            is accurate and complete, Mr D Food makes no representations or
            warranties, whether express or implied, as to the quality,
            timeliness, operation, integrity, availability or functionality of
            the Portal (including the Ad Platform and/or Ad Services) or as to
            the accuracy, completeness or reliability of any information on the
            Portal. Such information is provided ‘as is’ without warranty of any
            kind, either express or implied, including the implied warranties of
            merchantability, fitness for a particular purpose, completeness, or
            non-infringement, as may be allowed in law. If any such
            representations or warranties are made by Mr D Food’s
            representatives, Mr D Food shall not be bound thereby.
          </li>
          <li>
            Mr D Food disclaims liability for any damage, loss or expenses,
            whether direct, indirect or consequential in nature, arising out of
            or in connection with your access to, use of, or inability to use
            the Portal (including the Ad Platform and/or Ad Services) and/or any
            content therein unless otherwise provided by law.
          </li>
          <li>
            In addition to the disclaimers contained above and elsewhere in
            these Terms of Use, Mr D Food also makes no warranty or
            representation, whether express or implied, that the information or
            files available on the Portal are free of viruses, spyware, malware,
            trojans, destructive materials or any other data or code which is
            able to corrupt, destroy, compromise, disrupt, disable, harm,
            jeopardise or otherwise impede in any manner the operation,
            stability, security, functionality or content of your computer
            system, computer network, hardware or software in any way. You
            accept all risk associated with the existence of such viruses,
            destructive materials or any other data or code which is able to
            corrupt, compromise, jeopardise, disrupt, disable, harm or otherwise
            impede in any manner the operation or content of a computer system,
            computer network, any handset or mobile device, or your hardware or
            software, save where such risks arise due to the gross negligence or
            wilful misconduct of Mr D Food, its employees, agents or authorised
            representatives. Mr D Food thus disclaims all liability for any
            damage, loss or liability of any nature whatsoever arising out of or
            in connection with your access to or use of the Portal (including
            the Ad Platform and/or Ad Services).
          </li>
          <li>
            We will use reasonable endeavours to maintain the availability of
            the Portal (including the Ad Platform and/or Ad Services), except
            during scheduled maintenance periods, and are entitled to
            discontinue providing the Portal (including the Ad Platform and/or
            Ad Services), or any part thereof with or without notice to you.
          </li>

          <li>
            Mr D Food may in its sole discretion terminate, suspend and/or
            modify the Portal (including the Ad Platform and/or Ad Services),
            with or without notice to you. Mr D Food retains the right to
            immediately prevent or restrict access to the Portal (including the
            Ad Platform and/or Ad Services), or take any other action to prevent
            or restrict your access to the Portal (including the Ad Platform
            and/or Ad Services).
          </li>
        </ol>
      </li>
      <li>
        <strong>NOTICES AND DOMICILIA</strong>
        <ol>
          <li>
            Subject to clauses 14.2 and 14.3 below, all domicilia provisions set
            out in the Restaurant Agreement are hereby incorporated by reference
            into these Terms of Use, mutatis mutandis, and are binding on and
            enforceable against the Restaurant Partner accordingly.
          </li>
          <li>
            Any notice posted on the Portal shall be deemed to have been
            received by you one Business Day after posting.
          </li>
          <li>
            You must send all operational notices or queries and other
            communications relating to Mr D Food in connection with these Terms
            of Use, by sending an email to
            <a>restaurantportal@mrdfood.com</a>.
          </li>
        </ol>
      </li>
      <li>
        <strong>GENERAL</strong>
        <ol>
          <li>
            These Terms of Use shall be governed by and interpreted in
            accordance with the laws of the Republic of South Africa. Your
            continued use of the Portal (including the Ad Platform and/or Ad
            Services) will constitute your consent and submission to the
            jurisdiction of the South African courts regarding all proceedings,
            transactions, applications or the like instituted by either Party
            against the other in connection with these Terms of Use.
          </li>
          <li>
            In the event of any dispute arising between you and Mr D Food in
            connection with these Terms of Use, you hereby consent to the
            non-exclusive jurisdiction of the High Court of the Republic of
            South Africa (Western Cape Division, Cape Town), notwithstanding
            that the quantum in the action or proceedings may otherwise fall
            below the monetary jurisdiction of that court.
          </li>
          <li>
            Our failure to enforce your strict performance of any provision of
            these Terms of Use shall not constitute a waiver of our right to
            enforce such provision or any other provision hereof subsequently.
          </li>
          <li>
            Except as provided in the Restaurant Agreement, the Restaurant
            Partner and we are independent contractors hereunder, and nothing in
            these Terms of Use shall create any partnership, joint venture,
            agency, franchise, sales representative, or employment relationship
            between us, and furthermore you shall have no authority to make or
            accept any offers or representations on our behalf.
          </li>
          <li>
            If any provision of these Terms of Use shall be deemed unlawful,
            void, or for any reason unenforceable, then that provision shall be
            deemed severable from these Terms of Use and shall not affect the
            validity and enforceability of any remaining provisions. These Terms
            of Use, read with the Restaurant Agreement, represents the entire
            agreement between the Parties with respect to the Portal (including
            the Ad Platform and/or Ad Services) and any related subject matter
            described herein, and supersedes any previous or contemporaneous
            oral or written agreements and understandings.
          </li>
        </ol>
      </li>
    </ol>
  </div>
  <div class="modal-footer btn-group">
    <button
      type="button"
      class="btn primary btn-full"
      (click)="close()"
      id="btnAlertOkay"
    >
      Close
    </button>
  </div>
</div>
