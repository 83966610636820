import {
  Component, OnInit, OnDestroy, Renderer2, ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  map, switchMap, tap, delay,
} from 'rxjs/operators';
import {
  of, combineLatest, Subscription,
} from 'rxjs';
import { UsersService } from 'src/app/modules/admin/users/users.service';
import { IUser } from 'src/app/entities/user';
import { RestaurantsService } from 'src/app/modules/admin/restaurants/restaurants.service';
import { IRestaurantSet } from 'src/app/entities/restaurant-set';
import { userRoles } from 'src/app/entities/user-role';

@Component({
  selector: '[impersonation]',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss'],
  })
export class ImpersonationComponent implements OnInit, OnDestroy {
  viewAsUserId: string;
  viewAsUserName: string;

  viewForRestaurantSetId: string;
  viewForRestaurantSetName: string;

  private subRouteEvents: Subscription;

  constructor(
    private route: ActivatedRoute,
    private users: UsersService,
    private restaurants: RestaurantsService,
    private el: ElementRef,
    private rd: Renderer2,
  ) {

  }

  ngOnInit(): void {
    this.subRouteEvents = this.route.queryParamMap.pipe(
      delay(0),
      map((map) => ({
        userId: map.get('as'),
        restaurantSetId: map.get('for'),
      })),
      tap((p) => {
        this.viewAsUserId = p.userId;
        this.viewForRestaurantSetId = p.restaurantSetId;
        // if (p.userId || p.restaurantSetId) {
        //   this.el.nativeElement.closest('body').classList.add('impersonating-overview');
        // } else {
        //   this.el.nativeElement.closest('body').classList.remove('impersonating-overview');
        // }
      }),
      switchMap((p) => {
        const user$ = p.userId ? this.users.getUser(p.userId) : of(null as IUser);
        const restaurantSet$ = p.restaurantSetId
          ? this.restaurants.getRestaurantSet(p.restaurantSetId) : of(null as IRestaurantSet);
        return combineLatest([user$, restaurantSet$]);
      }),
      map(([user, restaurantSet]) => ({
        userName: user ? `${user.first_name} ${user.last_name} (${userRoles.find((r) => r.id === user.role)?.name ?? 'unknown role'})` : null,
        restaurantSetName: restaurantSet ? restaurantSet.name : null,
      })),
    ).subscribe((names) => {
      this.viewAsUserName = names.userName;
      this.viewForRestaurantSetName = names.restaurantSetName;
    });
  }

  ngOnDestroy() {
    this.subRouteEvents.unsubscribe();
  }
}
